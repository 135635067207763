@use "global" as *;


.icon-box {
  width: prem(16);
  height: prem(16);
  display: block;
  position: relative;

  @include mq('md') {
    width: prem(24);
    height: prem(24);
  }
}

.icon-box.icon-box--works {
  width: prem(18);
  height: prem(18);

  @include mq('md') {
    width: prem(16);
    height: prem(16);
  }
}

.icon-box.icon-box--works .icon-box__bar01{
  height: 1px;
}

.icon-box.icon-box--works .icon-box__bar02{
  width: 1px;
}

.icon-box__bar01 {
  position: absolute;
  width: 100%;
  height: prem(2);
  background-color: $main;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  transition: 0.3s ease-out;
}

.icon-box__bar02 {
  position: absolute;
  width: prem(2);
  height: 100%;
  background-color: $main;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  transition: 0.3s ease-out;
}

.icon-box__bar02.is-open {
  transform: rotate(-90deg);
}
