@use "global" as *;

.greeting {
    padding-top: prem(52);
    padding-bottom: prem(68);

    @include mq('md') {
        padding-top: prem(88);
        padding-bottom: prem(104);
    }
}

.greeting__bg {
    background: url(../images/company/greeting-bg.webp);
    background-repeat: no-repeat;
    background-position: right prem(147);
    background-size: 77.6% auto;
    padding-bottom: prem(40);

    @include mq('md') {
        background: url(../images/company/greeting-bg_pc.webp)no-repeat right center/60% 100%;
        padding-top: prem(64);
        padding-bottom: prem(65);
    }
}

.greeting__inner {
    width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
    margin-right: auto;
    margin-left: auto;

    @include mq("md") {
        max-width: $inner + strip-unit($padding-pc) * 2;
        padding-right: $padding-pc;
        padding-left: $padding-pc;
    }
}

.greeting__contents {
    display: flex;
    flex-direction: column;
    gap: prem(14);

    @include mq('md') {
        gap: prem(63);
    }
}

.greeting__head {}

.greeting__wrapper {
    display: flex;
    flex-direction: column;
    gap: prem(28);

    @include mq('md') {
        flex-direction: row;
        gap: prem(89);
    }
}

.greeting__title {}


.greeting__message {
    font-size: prem(19);
    font-weight: $semi-bold;
    line-height: 1.8;
    font-family: $second-font-family;
    color: $green;
    margin-top: prem(3);
    letter-spacing: -0.03em;


    @include mq('md') {
      font-size: prem(23);
      line-height: 1.8;
      margin-top: prem(5);
      letter-spacing: 0em;

    }

}

.u-mobile {}

.greeting__body {}

.greeting__text {
    font-size: prem(14);
    line-height: 1.8;
    font-weight: $normal;

    @include mq('md') {
        font-size: prem(16);
        line-height: 1.8;
    }
}

.greeting__name {
    margin-top: prem(18);
    font-weight: $normal;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: prem(14);
    gap: prem(12);

    @include mq('md') {
        margin-top: prem(36);
        font-size: prem(16);
        gap: prem(16);
    }
}

.greeting__name span:nth-of-type(2) {
    font-size: prem(15);
    font-weight: $semi-bold;
    font-family: $second-font-family;

    @include mq('md') {
        font-size: prem(18);
    }
}
