@use "global" as *;

.works-sub {
  padding: prem(52) 0px prem(69);

  @include mq('md') {
    padding: prem(88) 0px prem(128);
  }
}

.works-sub__inner {
  width: 100%;
  padding-right: $padding-sp;
  padding-left: $padding-sp;
  margin-right: auto;
  margin-left: auto;

  @include mq("md") {
    max-width: $inner + strip-unit($padding-pc) * 2;
    padding-right: $padding-pc;
    padding-left: $padding-pc;
  }
}


.works-sub__contents {}

.works-sub__category-box {
  padding: prem(20) prem(17.5) prem(32);
  background-color: $green;
  border-radius: prem(10);

  @include mq('md') {
    padding: prem(30) prem(40) prem(31);
  }
}

.works-sub__category-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: prem(14);

  @include mq('md') {
    flex-direction: row;
    gap: prem(49);
    text-align: center;
  }

}

.works-sub__category-title {
  color: $white;
  font-size: prem(19);
  font-family: $english-font-family;

  @include mq('md') {
    font-size: prem(26);
  }
}

.works-sub__category-button {
  display: flex;
  flex-wrap: wrap;
  gap: prem(12) prem(6.5);

  @include mq('md') {
    gap: prem(25);
  }
}

.works-sub__category-button li {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid $white;
  color: $white;
  font-size: prem(14);
  padding: prem(0) prem(23.5);
  height: prem(34);
  border-radius: prem(7);
  transition: .3s ease-out;
  cursor: pointer;

  @include mq('md') {
    font-size: prem(16);
    padding: prem(0) prem(39);
  }
}

.works-sub__category-button li:hover,
.works-sub__category-button li.is-active {
  background-color: $white;
  color: $green;
}

.works-sub__category-button li.is-active {
  cursor: initial;
}


.works-sub__container{
  margin-top: prem(36);

  @include mq('md') {
    margin-top: prem(64);
  }
}


.works-sub__items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: prem(32);

  @include mq('md') {
    gap: prem(56) prem(41);
    grid-template-columns: repeat(3, 1fr);
  }
}


.works-sub__item {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  display: none;
}

.works-sub__item:hover{
  cursor: pointer;
}


.works-sub__picture {
  display: block;
  aspect-ratio: 335/210;
  position: relative;
  width: 100%;

  @include mq('md') {
    aspect-ratio: 346/320;
  }
}

.works-sub__picture img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  object-fit: cover;
}



.works-sub__name {
  font-weight: $normal;
  font-size: prem(14);
  line-height: 1.8;
  margin-top: prem(12);
  margin-bottom: prem(8);

  @include mq('md') {
   font-size: prem(16);
   margin-top: prem(16);
  }
}

.works-sub__tag {
  font-size: prem(12);
  font-weight: $normal;
  line-height: 1;
  padding: prem(5) prem(12);
  border-radius: 999px;
  color: white;
  display: inline-block;
  margin-top: auto;

  @include mq('md') {
   font-size: prem(14);
   padding: prem(6) prem(14);
  }
}

.works-sub__tag.works-sub__tag--detached-house  {
  background-color: $green;
}

.works-sub__tag.works-sub__tag--others {
  background-color: $gray
}

.works-sub__tag.works-sub__tag--apartment {
  background-color: $main;
}

.works-sub__tag.works-sub__tag--nursing-facility {
  background-color: $green;
}


.works-sub__button {
  display: none;
  margin-top: prem(40);
  text-align: center;

  @include mq('md') {
    margin-top: prem(65);
  }
}

.works-sub__button.is-active{
  display: block;
}


.works-sub__button.is-open .icon-box__bar01 {
	transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.works-sub__button.is-open .icon-box__bar02 {
	transform: translateX(-50%) translateY(-50%) rotate(45deg);
}




