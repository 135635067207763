@use "global" as *;

.mv {
  height: 100svh;
}

.mv__swiper {
  height: inherit;
  display: flex;
  flex-direction: column;


  @include mq('md') {
    display: flex;
    flex-direction: row;
  }
}


.mv__catch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -79%);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  color: $white;
  z-index: 110;

  @include mq('md') {
  transform: translate(-50%, -66%);
  }
}

.mv__catch img {
  width: prem(123);
  height: prem(132);
  opacity: 0;
  transform: translateY(prem(40));

  @include mq('md') {
    width: prem(180);
    height: prem(193);
  }
}

.mv__message-kerning{
  letter-spacing: -0.4em;
}

.mv__message {
  font-size: prem(22);
  font-weight: $semi-bold;
  font-family: $second-font-family;
  margin-top: prem(15);
  letter-spacing: 0.04em;
  padding-left: prem(10);


  @include mq('md') {
    font-size: prem(32);
    margin-top: prem(21);
  }
}

.mv__message span{
  opacity: 0;
}

.mv__message-sub {
  font-family: $english-font-family;
  font-size: prem(13);
  font-weight: $normal;
  letter-spacing: 0.05em;
  margin-top: prem(12);
  opacity: 0;
  transform: translateY(prem(20));

  @include mq('md') {
    font-size: prem(18);
  }
}



.mv__news {
  border-radius: prem(10);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: prem(20);
  background-color: $white;
  padding: prem(15) prem(20) prem(14);
  max-width: calc(100% - 20px);
  width: 100%;
  z-index: 2;

  @include mq('md') {
    max-width: 1120px;
    padding: prem(16) prem(40);
    bottom: prem(40);
    width: calc(100% - 80px);
  }

}

.mv__news-contents {
  display: flex;
  flex-direction: column;

  @include mq('md') {
    flex-direction: row;
  }
}

.mv__news-title {
  line-height: 1;
  color: $main;
  font-family: $english-font-family;
  padding-bottom: prem(5);
  position: relative;

  @include mq('md') {
    font-size: prem(24);
    flex: 0 0 prem(86);
    margin-top: prem(10);
  }
}


.mv__news ul {
  display: flex;
  flex-direction: column;
  gap: prem(5);
  width: 100%;
}


.mv__news ul li a {
  display: block;
  margin-top: prem(6);
  padding-top: prem(8);
  border-top: 1px solid $lightgray;
  position: relative;

  @include mq('md') {
    display: flex;
    align-items: center;
    gap: prem(24);
    border-top: none;
    border-left: 1px solid $lightgray;
    padding-left: prem(24);
    padding-top: 0px;
    line-height: 1.8;
    margin: prem(7) 0 prem(7);
    padding-right: prem(49);
  }
}

.mv__news ul li a::before{
  @include mq('md') {
    content: "";
    position: absolute;
    display: block;
    width: prem(1);
    height: 100%;
    background-color: $lightgray;
    right: prem(49);
  }
}

.mv__news ul li a::after {
  content: "";
  position: absolute;
  display: block;
  background: url(../images/common/arrow02.webp)no-repeat center center/contain;
  width: prem(25);
  height: prem(7);
  top: 50%;
  transform: translateY(-50%);
  right: prem(0);

  @include mq('md') {
    right: prem(0);
  }
}

.mv__news-daytime {
  font-size: 12px;
  font-family: $english-font-family;
  font-weight: $normal;
  color: $gray;
  font-feature-settings: "palt" 1;

  @include mq('md') {
    font-size: prem(15);
  }
}

.mv__news-text {
  font-size: prem(13);
  margin-top: prem(3);
  font-weight: $normal;
  transition: .3s ease-out;

  @include mq('md') {
    font-size: prem(16);
    margin-top: 0;
  }
}


.mv .swiper {
  width: 100%;
  height: 50%;

  @include mq('md') {
    width: 50%;
    height: 100%;
  }
}

.mv .swiper img {
  height: 100%;
  object-fit: cover;
}


.mv__swiper .swiper-slide.swiper-slide-active img,
.mv__swiper .swiper-slide.swiper-slide-duplicate-active img,
.mv__swiper .swiper-slide.swiper-slide-prev img {
    animation: scale 8s linear 0s normal both;
}

@keyframes scale {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
 }

 .mv__bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $green;
  z-index: 100;
  display: grid;
  place-items: center;
  pointer-events: none;
}


