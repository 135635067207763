@use "global" as *;

.card02__item-flex:nth-of-type(5) {

    @include mq('md') {
        flex-direction: column;
    }
}

.card02__item-flex {
    display: flex;
    flex-direction: column;
    color: $white;
    border-radius: prem(10) prem(10) 0 0;
    overflow: hidden;

    @include mq('md') {
        flex-direction: row;
        border-radius: prem(10);
    }
}

.card02__item:nth-of-type(5) .card02__item-flex {
    @include mq('md') {
        border-radius: prem(10) prem(10) 0 0;
    }
}

.card02__item-flex.card02__item-flex--rev {
    @include mq('md') {
        flex-direction: row-reverse;
    }
}

.card02__head {
    aspect-ratio: 335/200;

    @include mq('md') {
        flex: prem(478);
    }
}

.card02__head img {
    height: 100%;
    object-fit: cover;
}

.card02__body {
    display: flex;
    flex-direction: column;
    gap: prem(16);
    background-color: $green;
    padding: prem(30) prem(20) prem(23);
    border-radius: 0 0 prem(10) prem(10);

    @include mq('md') {
        padding: prem(62) prem(50) prem(48) prem(64);
        gap: prem(32);
        border-radius: prem(0);
        flex: prem(642);
    }
}


.card02__item-flex.card02__item-flex--rev .card02__body {
    @include mq('md') {
        padding: prem(62) prem(66) prem(48) prem(48);
    }
}

.card02__title {
    padding-left: prem(48);
    position: relative;

    @include mq('md') {
        padding-left: prem(73);
        font-size: prem(20);
    }
}

.card02__title::before {
    content: "";
    position: absolute;
    display: block;
    background: url(../images/common/01-icon.webp)no-repeat center center/contain;
    width: prem(32);
    height: prem(38);
    top: 50%;
    transform: translateY(-50%);
    left: prem(0);

    @include mq('md') {
        width: prem(49);
        height: prem(58)
    }
}

.card02__item:nth-of-type(5) .card02__body {
    border-radius: 0px;
}


.card02__item:nth-of-type(2) .card02__title::before {
    background: url(../images/common/02-icon.webp)no-repeat center center/contain;
}

.card02__item:nth-of-type(3) .card02__title::before {
    background: url(../images/common/03-icon.webp)no-repeat center center/contain;

}

.card02__item:nth-of-type(4) .card02__title::before {
    background: url(../images/common/04-icon.webp)no-repeat center center/contain;

}

.card02__item:nth-of-type(5) .card02__title::before {
    background: url(../images/common/05-icon.webp)no-repeat center center/contain;
}

.card02__item:nth-of-type(6) .card02__title::before {
    background: url(../images/common/06-icon.webp)no-repeat center center/contain;
}

.card02__text {
    font-size: prem(14);
    line-height: 1.8;
    font-weight: $normal;
    margin-top: prem(8);

    @include mq('md') {
        font-size: prem(16);
        margin-top: prem(14);
    }
}

.card02__role {
    padding: prem(29) 0 prem(34);
    background-color: $lightgray;
    border-radius: 0 0 prem(10) prem(10);

    @include mq('md') {
        padding: prem(43) 0 prem(34);
    }
}

.card02__role-subject {
    color: $green;
    font-size: prem(15);
    text-align: center;

    @include mq('md') {
        font-size: prem(18);
    }
}

.card02__container {
    margin-top: prem(16);
    color: $black;
    font-weight: $normal;
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;

    @include mq('md') {
        width: calc(100% - 96px);
        margin-top: prem(26);
    }

}

.card02__role-parent {
    display: flex;
    flex-direction: column;
    border: 1px solid $gray;
    background-color: $white;



    @include mq('md') {
        display: none;
    }
}

.card02__role-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: prem(12) prem(16) prem(10);
    line-height: 1.8;
    font-size: prem(13);

    @include mq('md') {
        border-left: 1px solid $gray;
    }
}

.card02__role-item:nth-of-type(3n + 1) {

    @include mq('md') {
        border-left: none;
    }
}

.card02__role-item:not(:nth-of-type(1)) {
    border-top: 1px solid $gray;
}


.card02__role-item:nth-of-type(10) {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.card02__role-item:nth-of-type(10) span {
    padding: prem(11) prem(16) prem(12);
    position: relative;
    width: 100%;
    text-align: center;
}


.card02__role-item:nth-of-type(10) span:nth-of-type(2)::after {
    content: "";
    background-image: linear-gradient(to right, $gray, $gray 3px, transparent 3px, transparent 6px);
    background-size: 6px 1px;
    background-repeat: repeat-x;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.card02__table {
    display: none;
    border-collapse: collapse;
    table-layout: fixed;
    background-color: $white;

    @include mq('md') {
     display: block;
    }
}



.card02__table td {
    border: 1px solid $gray;
    width: 33.3333%;
    padding: prem(14) prem(16);
    text-align: center;
    font-weight: $normal;
    line-height: 1.8;
    height: prem(90);
}

.card02__table tr:nth-of-type(4) .card02__table-main{
    border-right: 0px ;
}

.card02__table .card02__table-td {
    text-align: left;
    border-left: 1px dashed $gray;
}
