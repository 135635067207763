@use "global" as *;

.outline {
    padding-top: prem(48);

    @include mq('md') {
        padding-top: prem(104);
    }
}

.outline__inner {    width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
    margin-right: auto;
    margin-left: auto;

    @include mq("md") {
      max-width: $inner + strip-unit($padding-pc) * 2;
      padding-right: $padding-pc;
      padding-left: $padding-pc;
    }
}

.outline__title {
    padding-left: prem(11);

    @include mq('md') {
     padding-left: 0px;
    }
}

.outline__contents {
margin-top: prem(42);

@include mq('md') {
  margin-top: prem(70);
  max-width: 900px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
}

.outline__list {
    display: flex;
    flex-direction: column;
    gap: prem(17);

    @include mq('md') {
        gap: prem(32);
    }
}

.outline__list dl{
    display: flex;
    gap: prem(44);
    font-size: prem(14);
    line-height: 1.8;
    padding-bottom: prem(20);
    border-bottom: 1px solid $lightgray;

    @include mq('md') {
      font-size: prem(16);
      padding-bottom: prem(31.5);
      gap: prem(88);
    }
}

.outline__list dl dt{
    flex: prem(75);

    @include mq('md') {
        flex: prem(112);
    }
}

.outline__list dl dd{
    flex: prem(216);
    font-weight: $normal;
    display: flex;
    flex-direction: column;
    gap: prem(30);


    @include mq('md') {
        flex:prem(700);
        gap: prem(30);
    }
}


.outline__change dd:nth-of-type(1){
   display: flex;

   @include mq('md') {
     display: none;
   }
}


.outline__change dd:nth-of-type(2){
   display: none;

   @include mq('md') {
    display: block;
   }
}

.outline__skill-wrap{
    display: flex;
}

.outline__skill{
   width: 47%;
}

.outline__headcount{
    width: 53%;
 }
