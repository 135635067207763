@use "global" as *;

.philosophy {
    color: $white;
    background-color: $green;
    padding: prem(48) 0 prem(68);

    @include mq('md') {
        padding: prem(72) 0 prem(119);
    }
}

.philosophy__inner {
    width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
    margin-right: auto;
    margin-left: auto;

    @include mq("md") {
        max-width: $inner + strip-unit($padding-pc) * 2;
        padding-right: $padding-pc;
        padding-left: $padding-pc;
    }
}

.philosophy__title {
    text-align: center;
}


.philosophy__contents {
    margin-top: prem(42);
    letter-spacing: -0.03em;

    @include mq('md') {
        margin-top: prem(70);

    }
}

.philosophy__wrapper {
    display: flex;
    flex-direction: column;
    gap: prem(56);

    @include mq('md') {
        gap: prem(72);
    }
}

.philosophy__block {}

.philosophy__head {
    color: $green;
    background-color: $white;
    display: flex;
    align-items: center;
    border-radius: prem(10);
    padding: prem(18) prem(20) prem(15);

    @include mq('md') {
        padding: prem(24) prem(32);
        font-size: prem(20);
    }
}

.philosophy__number {
    font-size: prem(19);
    font-family: $english-font-family;
    flex: prem(36);

    @include mq('md') {
        flex: 55;
        font-size: prem(25);
    }
}

.philosophy__wish {
    font-family: $second-font-family;
    font-weight: $semi-bold;
    flex: prem(280);
    line-height: 1.8;
    padding-left: prem(19);
    position: relative;
    letter-spacing: 0em;

    @include mq('md') {
        flex: 1037;
        padding-left: prem(23);
    }
}

.philosophy__wish::before {
    content: "";
    position: absolute;
    display: block;
    width: prem(1);
    height: prem(45);
    background-color: $gray;
    top: 50%;
    transform: translateY(-50%);
    left: prem(3);
}

.philosophy__wish.philosophy__wish--1row::before {
    height: prem(15);
}

.philosophy__body {
    display: flex;
    flex-direction: column-reverse;
    gap: prem(27);
    margin-top: prem(24);

    @include mq('md') {
        flex-direction: row;
        gap: prem(64);
        margin-top: prem(43);
        width: calc(100% - prem(64));
        margin-left: auto;
        margin-right: auto;
    }
}

.philosophy__text-area {
    width: 100%;

    @include mq('md') {
        flex: prem(622);
    }
}

.philosophy__text-mass {
    display: flex;
    flex-direction: column;
    gap: prem(12);
    font-size: prem(15);

    @include mq('md') {
        gap: prem(18);
    }
}

.philosophy__text-mass:nth-of-type(2) {
    margin-top: prem(24);

    @include mq('md') {
        margin-top: prem(43);
    }
}

.philosophy__subject {
    font-size: prem(15);
    padding-left: prem(29);
    position: relative;
    letter-spacing: 0em;

    @include mq('md') {
        font-size: prem(18);
        padding-left: prem(30);
    }
}

.philosophy__subject::before {
    content: "";
    position: absolute;
    display: block;
    background: url(../images/company/philosophy-icon.png)no-repeat center center/contain;
    width: prem(21);
    height: prem(25);
    top: 50%;
    transform: translateY(-54%);
    left: prem(0);

    @include mq('md') {
        width: prem(22);
        height: prem(26);
    }
}

.philosophy__text {
    font-size: prem(14);
    font-weight: $normal;
    line-height: 1.8;
    letter-spacing: 0em;

    @include mq('md') {
        font-size: prem(16);
        line-height: 1.8;
    }

}

.philosophy__thumb {
    width: 100%;

    @include mq('md') {
        flex: prem(370);
        margin-top: prem(-3);
    }

}

.philosophy__picture {}
