@use "global" as *;


.icon-box02 {
  width: prem(16);
  height: prem(16);
  display: block;
  position: relative;

  @include mq('md') {
    width: prem(24);
    height: prem(24);
  }
}

.icon-box02.icon-box--works {
  width: prem(18);
  height: prem(18);

  @include mq('md') {
    width: prem(16);
    height: prem(16);
  }
}

.icon-box02__bar01 {
  position: absolute;
  width: 100%;
  height: prem(2);
  background-color: $main;
  top: 50%;
  transition: 0.3s ease-out;
}

.icon-box02__bar02 {
  position: absolute;
  width: prem(2);
  height: 100%;
  background-color: $main;
  left: 45%;
  top: 5%;
  transition: 0.3s ease-out;
}

.icon-box02__bar02.is-open {
  transform: rotate(-90deg);
}
