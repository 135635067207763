@use "global" as *;

.button01 {
    padding: prem(17) prem(41);
    border: 1px solid $white;
    border-radius: prem(10);
    color: $white;
    font-size: prem(14);
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: inline-block;

    @include mq('md') {
        padding: prem(17) prem(39) prem(18);
        font-size: prem(16)
    }
}

.button01:before{
    position: absolute;
    left:0;
    height:0;
    right:0;
    bottom:0;
}

.button01.button01--map{
    background-color: $white;
    color: $black;
}

.button01.button01--contact{
    padding: prem(21) prem(40) prem(19);

    @include mq('md') {
        padding: prem(24) prem(39) prem(25);
    }

    @media screen and (max-width: 370px) {
        padding: prem(21) prem(30) prem(19);
    }

}



.button01::before{
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: prem(-50);
    bottom: 0;
    left: 0;
    border-right: prem(50) solid transparent;
    border-bottom: prem(77) solid $white;
    transform: translateX(-100%);
    transition: 0.4s ease-in-out;
}

.button01.button01--black {
    border: 1px solid $black;
    color: $black;
}


.button01:hover {
    color: $green;
}

.button01:hover::before {
    transform: translateX(0);
}



.button01 span {
    line-height: 1;
    padding-right: prem(49);
    position: relative;

    @include mq('md') {
        padding-right: prem(49);
    }
}

.button01 span::after {
    content: "";
    position: absolute;
    display: block;
    background: url(../images/common/arrow01.webp)no-repeat center center/contain;
    width: prem(23);
    height: prem(9);
    top: 50%;
    transform: translateY(-50%);
    right: prem(-4);

    @include mq('md') {
        width: prem(26);
        height: prem(9);
        right: prem(-2);
    }
}

.button01.button01--black span::after,
.button01.button01--map span::after {
    background: url(../images/common/arrow02.webp)no-repeat center center/contain;
}

.button01:hover span::after{
    background: url(../images/common/arrow03.webp)no-repeat center center/contain;

}


