@use "global" as *;

.access {
    padding: prem(68) 0 prem(68);

    @include mq('md') {
        padding: prem(104) 0 prem(129);
    }
}

.access__inner {
    width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
    margin-right: auto;
    margin-left: auto;

    @include mq("md") {
        max-width: $inner + strip-unit($padding-pc) * 2;
        padding-right: $padding-pc;
        padding-left: $padding-pc;
    }
}

.access__title {
    text-align: center;
}

.access__contents {
    margin-top: prem(40);

    @include mq('md') {
        margin-top: prem(70);
    }
}

.access__wrapper {
    display: flex;
    flex-direction: column;
    gap: prem(20);

    @include mq('md') {
        flex-direction: row;
        gap: prem(36);
        width: 100%;
    }
}

.access__block {
    background-color: $lightgray2;
    padding-bottom: prem(19);
    width: 100%;
    display: flex;
    flex-direction: column;

    @include mq('md') {
     padding-bottom: prem(27);
    }
}


.access__map {
    width: 100%;
    overflow: hidden;
    aspect-ratio: 335/225;
    position: relative;

    @include mq('md') {
        aspect-ratio: 542/400;
    }
}

.access__map iframe {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.access__information {
    padding-left: 16px;
    padding-right: 16px;

    @include mq('md') {
        padding-left: 24px;
        padding-right: 22px;
    }
}

.access__subject {
    color: $main;
    font-size: prem(15);
    line-height: 1.8;
    padding-left: prem(27);
    position: relative;
    margin-top: prem(13);

    @include mq('md') {
        font-size: prem(17);
        padding-left: prem(33);
        margin-top: prem(17);
    }
}

.access__subject::before {
    content: "";
    position: absolute;
    display: block;
    background: url(../images/company/access-icon.png)no-repeat center center/contain;
    width: prem(16);
    height: prem(22);
    top: 50%;
    transform: translateY(-44%);
    left: prem(4);

    @include mq('md') {
        width: prem(20);
        height: prem(52);
        transform: translateY(-47%);
    }
}

.access__address {
    font-size: prem(14);
    line-height: 1.8;
    margin-top: prem(12);
    font-weight: $normal;
    padding-bottom: prem(15);

    @include mq('md') {
        font-size: prem(16);
        line-height: 1.8;
        margin-top: prem(16);
        padding-bottom: prem(24);
    }
}

.access__btn {
    padding-left: 16px;
    padding-right: 16px;

    @include mq('md') {
        padding-left: 24px;
        padding-right: 22px;
        margin-top: auto;
    }
}

.access__button-map {
    display: inline-block;
    padding: prem(18) prem(43) prem(16) prem(38);
    border: 1px solid $white;
    border-radius: prem(10);
    font-size: prem(14);
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: $white;

    @include mq('md') {
        padding: prem(17) prem(38) prem(18);
        font-size: prem(16)
    }
}

.access__button-map span {
    line-height: 1;
    padding-right: prem(49);
    position: relative;
    font-weight: $normal;

    @include mq('md') {
        padding-right: prem(49);
    }
}

.access__button-map span::after {
    content: "";
    position: absolute;
    display: block;
    background: url(../images/common/arrow02.webp)no-repeat center center/contain;
    width: prem(23);
    height: prem(9);
    top: 50%;
    transform: translateY(-50%);
    right: prem(-6);
    transition: .3s ease-out;

    @include mq('md') {
        width: prem(27);
        height: prem(10);
        right: prem(-1);
    }
}

.access__button-map:hover span::after{
     right: prem(-14);
}
