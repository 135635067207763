@use "global" as *;

.animation{

}


.animation__bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $main;
  z-index: 100;
  display: grid;
  place-items: center;
  pointer-events: none;
}
