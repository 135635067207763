@use "global" as *;

.contact {
    color: $white;
    padding: prem(31) 0 prem(48);
    background: url(../images/common/contact-bg.webp)no-repeat center center/cover;
    position: relative;

    @include mq('md') {
    padding: prem(56) 0 prem(80);
    background: url(../images/common/contact-bg_pc.webp)no-repeat center center/cover;
    }
}

.contact:before{
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($black, 0.6);
    z-index: 1;
}

.contact__inner {
    position: relative;
    width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
    margin-right: auto;
    margin-left: auto;
    z-index: 1;

    @include mq("md") {
        max-width: $inner + strip-unit($padding-pc) * 2;
        padding-right: $padding-pc;
        padding-left: $padding-pc;
    }
}

.contact__contents {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq('md') {
      flex-direction: row;
      gap: prem(30);
      justify-content: space-between;
      align-items: flex-start;
    }
}

.contact__title {

  @include mq('md') {

  }
}


.contact__main{
   display: flex;
   flex-direction: column;
   align-items: center;
   max-width: prem(827);
   width: 100%;
   margin-top: prem(34);

   @include mq('md') {
     font-size: prem(16);
     align-items: flex-start;
     margin-top: prem(21);
   }
}

.contact__head {
    font-size: prem(14);
    font-weight: $normal;
    line-height: 1.8;

    @include mq('md') {
      font-size: prem(16);
    }
}

.contact__body {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: prem(19);
    padding: 0 prem(32);

    @include mq('md') {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-top: prem(41);
      align-items: flex-start;
      padding: 0px;
    }
}

.contact__number {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: prem(2);
    padding-bottom: prem(33);
    border-bottom: 1px solid $white;
    width: 100%;

    @include mq('md') {
     border-bottom: 0px;
     border-right: 1px solid $white;
     max-width: prem(827);
     width: 48.7%;
     align-items: flex-start;
     gap: prem(7);
     padding-bottom: prem(6);
    }
}

.contact__number a {
    font-size: prem(30);
    line-height: 1.8em;
    padding-left: prem(51);
    position: relative;
    display: inline-block;
    font-family: $english-font-family;

    @include mq('md') {
      font-size: prem(32);
      padding-left: prem(48);
      flex: 50%;
    }
}

.contact__number a::before {
    content: "";
    position: absolute;
    display: block;
    background: url(../images/common/phone-icon.webp)no-repeat center center/contain;
    width: prem(43);
    height: prem(43);
    top: 50%;
    transform: translateY(-50%);
    left: prem(0);

    @include mq('md') {
        background: url(../images/common/phone-icon_pc.webp)no-repeat center center/contain;
        width: prem(40);
        height: prem(40);
    }
}

.contact__daytime {
    font-size: prem(14);
    line-height: 1;
    font-weight: $normal;

    @include mq('md') {
     font-size: prem(16);
     flex: 50%;
     padding-left: prem(38);
    }
}

.contact__button {
    display: flex;
    justify-content: right;
    margin-top:prem(31);

    @include mq('md') {
     width: calc(100% - 48.7%);
     margin-top: 0px;
     padding-top: prem(5);
    }
}

