@use "global" as *;

.header {
    height: prem(77);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 130;
    background-color: transparent;
    transition: .3s ease-out;
    opacity: 0;
    // transform: translateY(prem(-60));

    @include mq("md") {
        height: prem(107);
    }
}

.header.scroll-on {
    background-color: rgba($gray, 0.5);
}

.header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
    margin-right: auto;
    margin-left: auto;

    @include mq("md") {
        max-width: 1180px + strip-unit($padding-pc) * 2;
        padding-right: $padding-pc;
        padding-left: $padding-pc;
    }

    @media screen and (min-width: 1300px) {
        max-width: calc(100% - 120px);

    }
}

.header__logo {
    max-width: prem(203);
    height: inherit;
    width: 100%;
    position: inherit;
    z-index: 1000;


    @include mq("md") {
        max-width: prem(251);
        height: prem(59);
    }
}


.header__logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.header__nav {
    display: none;
    height: inherit;

    @include mq("md") {
        display: block;
    }
}

.header__nav-list {
    display: flex;
    height: inherit;
}

.header__nav-item {
    height: inherit;
    display: flex;
    align-items: center;
}

.header__nav-item.header__nav-item--contact {
    margin-left: prem(32);
}

.header__nav-item.header__nav-item--hover {
    margin-left: prem(32);
    display: flex;
    align-items: center;
}


.header__nav-item-flex span {
    padding-left: prem(32);
}

.header__nav-item a {
    padding: 0 prem(20);
    height: prem(40);
    display: flex;
    align-items: center;
    line-height: 1;
    color: $white;
    position: relative;

    @media screen and (min-width: 1300px) {
        padding: 0 prem(32);

    }
}


.header__nav-item a::after {
    content: "";
    position: absolute;
    display: block;
    width: 70%;
    height: 2px;
    background-color: $white;
    bottom: prem(-3);
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: .4s ease-out;
}

.header__nav-item a:hover::after {
    opacity: 1;
    bottom: prem(0);
}

.header__nav-item.header__nav-item--contact a {
    width: prem(176);
    height: prem(53);
    color: #fff;
    border: 2px solid $white;
    border-radius: prem(10);
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: .3s ease-out;

}

.header__nav-item.header__nav-item--contact a::after {
    display: none;
}

.header__nav-item.header__nav-item--contact a:hover {
    // background-color: $green;
    // border-color: $green;
    opacity: 0.7;
}

.header__nav-item.header__nav-item--contact a span {
    padding-left: prem(32);
    position: relative;
}

.header__nav-item.header__nav-item--contact a span::before {
    content: "";
    position: absolute;
    display: block;
    background: url(../images/common/mail-icon.webp)no-repeat center center/contain;
    width: prem(24);
    height: prem(24);
    top: 50%;
    transform: translateY(-50%);
    left: prem(0);
    transition: .3s ease-out;
}

// .header__nav-item.header__nav-item--contact a:hover span::before {
//     background: url(../images/common/mail-icon-hover.webp)no-repeat center center/contain;
// }


.header__hamburger {
    margin: 0;
    padding: 0;
    outline: none;
    position: relative;
    z-index: 999;
    border: 1px solid $white;
    border-radius: prem(5);
    width: prem(45);
    height: prem(45);
    cursor: pointer;
    transition: .3s ease-out;

    @include mq("md") {
        display: none;
    }
}


.header__hamburger span {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: prem(16);
    height: 1px;
    background-color: #fff;
    transition: .5s;
}

.header__hamburger span:nth-of-type(1) {
    top: prem(-6);
}

.header__hamburger span:nth-of-type(2) {
    top: 0;
}

.header__hamburger span:nth-of-type(3) {
    top: prem(6);
}

.header__hamburger.is-open span:nth-of-type(1) {
    top: 0;
    transform: translateX(-50%) rotate(45deg);
}

.header__hamburger.is-open span:nth-of-type(2) {
    opacity: 0;
}

.header__hamburger.is-open span:nth-of-type(3) {
    top: prem(-2);
    transform: translateX(-50%) rotate(-45deg);
}

.header__drawer {
    padding: prem(122) 0 prem(100);
    display: none;
    position: absolute;
    z-index: 900;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba($green, 1.0);
    overflow-y: scroll;
    scrollbar-width: none;
}

.header__drawer::-webkit-scrollbar {
    display: none;
}

.header__drawer-list {
    display: flex;
    flex-direction: column;
    gap: prem(23);
}

.header__drawer-item a {
    font-size: prem(17);
    line-height: 2.8;
    display: block;
    color: $white;
    text-align: center;

}

.header__drawer-item.header__drawer-item--sub {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: prem(28);
    padding-top: prem(20);
}

.header__drawer-item--sub a {
    font-size: prem(15);
    line-height: 3;
    padding: prem(15) 0;
    font-weight: $regular;
}


.header__privacy-mark {
    padding-top: prem(12);
    width: 100%;
    text-align: center;
}
