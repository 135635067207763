@use "global" as *;

.policy {
  padding: prem(52) 0 prem(68);

  @include mq('md') {
    padding: prem(89) 0 prem(124);
  }
}



.policy__inner {
  width: 100%;
  padding-right: $padding-sp;
  padding-left: $padding-sp;
  margin-right: auto;
  margin-left: auto;
  position: relative;

  @include mq("md") {
    max-width: $inner + strip-unit($padding-pc) * 2;
    padding-right: $padding-pc;
    padding-left: $padding-pc;
    top: 0px;
    position: initial;
  }
}

.policy__contents {
  display: flex;
  flex-direction: column;
  gap: prem(23);

  @include mq('md') {
    gap: prem(40);
  }
}

.policy__mass:not(:nth-of-type(1)) .policy__text{

  @include mq('md') {
    margin-top: prem(16);
  }
}

.policy__heading {
  font-size: prem(17.5);
  line-height: 1.9;
  padding-bottom: prem(10);
  border-bottom: 1px solid $lightgray;
  font-weight: $semi-bold;

  @include mq('md') {
    font-size: prem(22);
    line-height: 1.9;
  }
}

.policy__tips {
  margin-top: prem(23);

  @include mq('md') {
    margin-top: prem(41);
  }
}

.policy__tips:not(:nth-of-type(3)) {

  @include mq('md') {
    margin-top: prem(32);
  }
}

.policy__title {
  color: $main;

  @include mq('md') {
    font-size: prem(20);
    line-height: 1.9;
    ;
  }
}

.policy__text {
  font-size: prem(14);
  font-weight: $normal;
  line-height: 1.9;
  margin-top: prem(11);

  @include mq('md') {
    font-size: prem(16);
    line-height: 1.9;
    margin-top: prem(7);
  }
}

.policy__text.policy__text--first {
  @include mq('md') {
    margin-top: prem(15);
  }
}

.policy__table {
  margin-top: prem(13);
  border-collapse: collapse;
  width: 100%;


  @include mq('md') {
    margin-top: prem(15);
    width: calc(100% - prem(60));
  }
}


.policy__table th,
.policy__table td {
  font-size: prem(14);
  line-height: 1.9;
  border: 1px solid $gray;
  padding: prem(9) 0px;
  text-align: left;

  @include mq('md') {
    font-size: prem(16);
    padding: prem(15) 0px;
  }
}

.policy__table tr th {
  background-color: $lightgray;
  width: prem(116);
  padding-left: prem(23);
  padding-right: prem(23);
  font-weight: $medium;

  @include mq('md') {
    width: prem(240);
    padding-left: prem(48);
  }

}

.policy__table tr td {
  font-weight: $normal;
  padding-left: prem(18);
  padding-right: prem(12);

  @include mq('md') {
    padding-left: prem(46);
    padding-right: prem(20);
  }
}

.policy__text {
  font-size: prem(14);
  font-weight: $normal;
  line-height: 1.9;
}
