@use "global" as *;

.reasons {
  background-color: $green;
  padding: prem(48) 0 prem(68);

  @include mq('md') {
    padding: prem(80) 0 prem(120);
  }
}


.reasons__inner {
  width: 100%;
  padding-right: $padding-sp;
  padding-left: $padding-sp;
  margin-right: auto;
  margin-left: auto;

  @include mq("md") {
    max-width: $inner + strip-unit($padding-pc) * 2;
    padding-right: $padding-pc;
    padding-left: $padding-pc;
  }
}

.reasons__contents {
  margin-top: prem(39);

  @include mq('md') {
    margin-top: prem(62);
  }
}

.reasons-text-wrapper {

  @include mq('md') {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.reasons__message {
  font-size: prem(18);
  color: $white;
  font-family: $second-font-family;
  font-weight: 500;
  line-height: 1.8;
  font-weight: $semi-bold;
  letter-spacing: 0.03em;

  @include mq('md') {
    font-size: prem(23);
    letter-spacing: -0.01em;
  }

}

.reasons__button-pc {
  justify-content: center;

  @include mq('md') {
    display: flex;
  }
}


.reasons__button {
  justify-content: center;
}

.reasons__button.reasons__button--pc {
  display: none;

  @include mq('md') {
    display: flex;
  }
}

.reasons__button.reasons__button--sp {
  display: flex;
  margin-top: prem(36);

  @include mq('md') {
    display: none;
  }
}

.reasons__list {
  margin-top: prem(34);

  @include mq('md') {
    margin-top: prem(54);
  }
}

.reasons__list ul {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: prem(24);

  @include mq('md') {
    grid-template-columns: repeat(3, 1fr);
    gap: prem(40) prem(41);

  }
}

.reasons__list ul li {
  display: flex;
  flex-direction: column;
  aspect-ratio: 335/218;
  background-color: $white;
  border-radius: prem(10);
  overflow: hidden;

  @include mq('md') {
    aspect-ratio: 346/264;
  }
}


.reasons__picture {
  content: "";
  display: block;
  aspect-ratio: 335/165;
  position: relative;

  @include mq('md') {
    aspect-ratio: 346/200;
  }
}

.reasons__subject {
  display: flex;
  align-items: center;
  gap: prem(12);
  line-height: 1;
  aspect-ratio: 335/53;
  padding: 0 prem(16);
  font-size: prem(14);

  @include mq('md') {
    aspect-ratio: 346/64;
    font-size: prem(16);
    padding: 0 prem(24);
    gap: prem(17);
  }
}

.reasons__list ul li span {
  font-family: $english-font-family;
  color: $main;
  font-size: prem(16);

  @include mq('md') {
    font-size: prem(18);
  }
}

.reasons__picture img {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
