@use "global" as *;

.reasons-sub {
    padding: prem(44) 0 prem(67);
    background: url(../images/reasons/logo.webp);
    background-position: center prem(44);
    background-repeat: no-repeat;
    background-size: prem(123) prem(132);

    @include mq('md') {
        padding: prem(44) 0 prem(129);
        background-position: center prem(64);
        background-size: prem(134) prem(144);

    }
}

.reasons-sub__inner {
    width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
    margin-right: auto;
    margin-left: auto;

    @include mq("md") {
        max-width: $inner + strip-unit($padding-pc) * 2;
        padding-right: $padding-pc;
        padding-left: $padding-pc;
    }
}

.reasons-sub__message {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.8;
    color: $green;
    font-weight: $semi-bold;
    font-family: $second-font-family;
    font-size: prem(23);
    margin-top: prem(53);

    @include mq('md') {
        font-size: prem(34);
        flex-direction: row;
        justify-content: center;
        margin-top: prem(124);
    }
}

.reasons-sub__text {
    font-size: prem(16);
    font-family: $second-font-family;
    font-weight: $semi-bold;
    line-height: 1.8;
    text-align: center;
    margin-top: prem(12);
    color: $green;
    letter-spacing: -0.02em;

    @include mq('md') {
        margin-top: prem(10);
        font-size: prem(19);
        letter-spacing: 0em;
    }
}

.reasons-sub__card {
    margin-top: prem(50);
    display: flex;
    flex-direction: column;
    gap: prem(32);

    @include mq('md') {
      margin-top: prem(80);
      gap: prem(65);
    }
}
