@use "global" as *;

.business-sub {
    padding: prem(52) 0 prem(68);

    @include mq('md') {
        padding: prem(88) 0 prem(128);
    }

}

.business-sub__inner {
    width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
    margin-right: auto;
    margin-left: auto;
    @include mq("md") {
      max-width: $inner + strip-unit($padding-pc) * 2;
      padding-right: $padding-pc;
      padding-left: $padding-pc;
    }
}


.business-sub__card {
    display: flex;
    flex-direction: column;
    gap: prem(47);

    @include mq('md') {
        gap: prem(89);
    }
}






