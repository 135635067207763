@use "global" as *;

.footer {
  background-color: $white;
  padding: prem(41) 0 prem(24);

  @include mq('md') {
    padding: prem(56) 0 prem(20);
  }
}

.footer__inner {
  width: 100%;
  padding-right: $padding-sp;
  padding-left: $padding-sp;
  margin-right: auto;
  margin-left: auto;

  @include mq("md") {
    max-width: $inner + strip-unit($padding-pc) * 2;
    padding-right: $padding-pc;
    padding-left: $padding-pc;
  }
}


.footer__block-upper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__block-upper img {
  width: prem(241);

  @include mq('md') {
    width: prem(251);
  }
}


.footer__nav {
  margin-top: prem(16);

  @include mq('md') {
    margin-top: prem(41);
  }
}

.footer__nav-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: prem(8);

  @include mq('md') {
    flex-direction: row;
    gap: prem(104);
  }
}

.footer__nav-item a {
  font-size: prem(14);
  line-height: 3.2;
  position: relative;
  display: inline-block;

  @include mq('md') {
    font-size: prem(16);
    line-height: 1.8;
  }
}


.footer__nav-item a::after {
  content: "";
  position: absolute;
  display: block;
  width: 0%;
  height: 1px;
  background-color: $black;
  bottom: prem(-6);
  left: 50%;
  transform: translateX(-50%);
  transition: .3s ease-out;
}

.footer__nav-item a:hover::after {
  @include mq('md') {
    width:110%;
  }
}

.footer__block-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid $lightgray;
  padding: prem(25) 0 0;
  margin-top: prem(29);
  font-size: prem(13);


  @include mq('md') {
    flex-direction: row;
    justify-content: space-between;
    margin-top: prem(46);
    align-items: flex-start;
  }

}

.footer__address {
  font-weight: $normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: -0.01em;

  @include mq('md') {
    align-items: flex-start;
  }
}

.footer__address span {
  letter-spacing: 0.01em;
  line-height: 1.8;
  text-align: center;

  @include mq('md') {
    text-align: left;
  }
}

.footer__number {
  margin-top: prem(2);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: prem(14);

  @include mq('md') {
    margin-top: prem(3);
    gap: prem(18);
  }
}

.footer__sub-wrapper {
  display: flex;
  flex-direction: column;
  font-weight: $normal;

  @include mq('md') {
    align-items: flex-end;
  }
}

.footer__sub {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: prem(29);
  margin-top: prem(24);

  @include mq('md') {
    gap: prem(32);
    margin-top: prem(3);
    font-size: prem(14);
    margin-top: prem(1);
  }

}

.footer__sub a {
  line-height: 1.8em;
  color: $gray;
  position: relative;
}


.footer__sub a::after {
  content: "";
  position: absolute;
  display: block;
  opacity:0;
  width: 100%;
  height: 1px;
  background-color: $gray;
  bottom: prem(-3);
  left: 0px;
  transition: .5s ease-out;
}


.footer__sub a:hover::after{
    opacity:1;
  }

.footer__privacy-mark {
  text-align: center;
  line-height: 1;
  margin-top: prem(18);

  @include mq('md') {
    margin-top: prem(26);
  }
}
