@use "global" as *;

.mv-sub {
    aspect-ratio: 375/230;
    color: $white;

    @include mq('md') {
        background: url(../images/reasons/mv-bg_pc.webp)no-repeat center center/cover;
        aspect-ratio: 1300/400;
    }
}

.mv-sub.mv-sub--reasons {
    background: url(../images/reasons/mv-bg.webp)no-repeat center center/cover;

    @include mq('md') {
        background: url(../images/reasons/mv-bg_pc.webp)no-repeat center center/cover;
    }
}

.mv-sub.mv-sub--company {
    background: url(../images/company/mv-bg.webp)no-repeat center center/cover;

    @include mq('md') {
        background: url(../images/company/mv-bg_pc.webp)no-repeat center center/cover;
    }
}
.mv-sub.mv-sub--business {
    background: url(../images/business/mv-bg.webp)no-repeat center center/cover;

    @include mq('md') {
        background: url(../images/business/mv-bg_pc.webp)no-repeat center center/cover;
    }
}
.mv-sub.mv-sub--news {
    background: url(../images/news/mv-bg.webp)no-repeat center center/cover;

    @include mq('md') {
        background: url(../images/news/mv-bg_pc.webp)no-repeat center center/cover;
    }
}
.mv-sub.mv-sub--policy {
    background: url(../images/policy/mv-bg.webp)no-repeat center center/cover;

    @include mq('md') {
        background: url(../images/policy/mv-bg_pc.webp)no-repeat center center/cover;
    }
}
.mv-sub.mv-sub--works-sub {
    background: url(../images/works-sub/mv-bg.webp)no-repeat center center/cover;

    @include mq('md') {
        background: url(../images/works-sub/mv-bg_pc.webp)no-repeat center center/cover;
    }
}



.mv-sub__inner {
    width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    position: relative;

    @include mq("md") {
        max-width: $inner + strip-unit($padding-pc) * 2;
        padding-right: $padding-pc;
        padding-left: $padding-pc;
    }

}

.mv-sub__catch {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-46%);

    @include mq('md') {
        transform: translateY(-57%);
        left: 40px;
    }
}

.mv-sub__sub {
    font-family: $english-font-family;

    @include mq('md') {
        font-size: prem(22);
    }
}

.mv-sub__main {
    font-size: prem(20);
    font-family: $second-font-family;
    font-weight: $semi-bold;
    margin-top: prem(5);

    @include mq('md') {
        font-size: prem(30);
        margin-top: prem(10);
    }
}

.mv-sub__text {
    position: absolute;
    right: 20px;
    bottom: prem(15);
    font-size: prem(14);
    font-weight: normal;

    @include mq('md') {
        font-size: prem(16);
        bottom: prem(26);
        right: 40px;
    }
}

.mv-sub__text a{
    position: relative;
}

.mv-sub__text a::after {
    content: "";
    position: absolute;
    display: block;
    opacity:0;
    width: 100%;
    height: 1px;
    background-color: $white;
    bottom: prem(-3);
    left: 0px;
    transition: .5s ease-out;
  }


  .mv-sub__text a:hover::after{
      opacity:1;
    }
