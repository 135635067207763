@use "global" as *;


.privacy-mark{
    font-size: prem(11);
    font-weight: $normal;
    font-family: $english-font-family;
    color: $white;
    text-align: center;
    display: inline-block;
  }

.privacy-mark.privacy-mark--gray{
    color: $gray;

    @include mq('md') {
      font-size: prem(12);
    }
  }
