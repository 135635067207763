@use "global" as *;

.business {
  padding-top: prem(47);

  @include mq('md') {
    padding-top: prem(80);
  }
}


.business__contents{
   margin-top: prem(32);

   @include mq('md') {
     margin-top: prem(54);
   }
}

.business__card{
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @include mq('md') {
    grid-template-columns: repeat(3, 1fr);
  }
}

