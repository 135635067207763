@use "global" as *;

.card03__item{
    padding-top: prem(52);
    margin-top: prem(-52);

    @include mq('md') {
        padding-top: prem(88);
        margin-top: prem(-88);
    }
}


.card03__item-flex {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}


.card03__head {
    aspect-ratio: 335/225;
    position: relative;

    @include mq('md') {
        aspect-ratio: 1120/400;
    }
}

.card03__head:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($black, 0.6);
    height: 100%;
    z-index: 1;
}

.card03__head img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    height: 100%;
    object-fit: cover;
}


.card03__head:after {
    content: "";
    position: absolute;
    display: block;
    z-index: 2;
}

.card03__item:nth-of-type(1) .card03__head:after {
    background: url(../images/business/business-sub-text01.webp)no-repeat center center/contain;
    width: prem(153);
    height: prem(49);
    bottom: prem(-12);
    right: prem(16);

    @include mq('md') {
        background: url(../images/business/business-sub-text01_pc.webp)no-repeat center center/contain;
        width: prem(307);
        height: prem(173);
        bottom: prem(-59);
        right: prem(23);
    }
}

.card03__item:nth-of-type(2) .card03__head:after {
    background: url(../images/business/business-sub-text02.webp)no-repeat center center/contain;
    width: prem(290);
    height: prem(96);
    bottom: prem(-7);
    right: prem(15);

    @include mq('md') {
        background: url(../images/business/business-sub-text02_pc.webp)no-repeat center center/contain;
        width: prem(796);
        height: prem(173);
        bottom: prem(-49);
        right: prem(23);
    }
}

.card03__item:nth-of-type(3) .card03__head:after {
    background: url(../images/business/business-sub-text03.webp)no-repeat center center/contain;
    width: prem(209);
    height: prem(48);
    bottom: prem(-8);
    right: prem(17);

    @include mq('md') {
        background: url(../images/business/business-sub-text03_pc.webp)no-repeat center center/contain;
        width: prem(409);
        height: prem(173);
        bottom: prem(-52);
        right: prem(28);
    }
}

.card03__body {
    display: flex;
    flex-direction: column;
    gap: prem(16);
    padding: prem(29) prem(12) 0px;

    @include mq('md') {
        flex-direction: row;
        align-items: center;
        padding: prem(18) prem(24) 0px;
        gap: prem(64);
    }
}

.card03__title {
    padding-left: prem(48);
    position: relative;
    display: flex;
    align-items: center;

    @include mq('md') {
        padding-left: prem(65);
        font-size: prem(20);
        flex: prem(165);
        margin-top: prem(15);
    }
}

.card03__title::before {
    content: "";
    position: absolute;
    display: block;
    background: url(../images/common/01-icon-green.webp)no-repeat center center/contain;
    width: prem(35);
    height: prem(41.5);
    top: 50%;
    transform: translateY(-50%);
    left: prem(0);

    @include mq('md') {
        width: prem(49);
        height: prem(58)
    }
}

.card03__item:nth-of-type(2) .card03__title::before {
    background: url(../images/common/02-icon-green.webp)no-repeat center center/contain;
}

.card03__item:nth-of-type(3) .card03__title::before {
    background: url(../images/common/03-icon-green.webp)no-repeat center center/contain;

}


.card03__text {
    font-size: prem(14);
    line-height: 1.8;
    font-weight: $normal;
    margin-top: prem(8);

    @include mq('md') {
        font-size: prem(16);
        margin-top: prem(14);
        flex: prem(843);

    }
}


