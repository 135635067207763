@use "global" as *;

.topBtn {
  width: 55px;
  height: 55px;
  justify-content: center;
  border: 2px solid $green;
  background-color: rgba($white, 0.5);
  align-items: center;
  z-index: 100;
  transition: opacity .3s, visibility .3s;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  right: prem(20);
  bottom: prem(20);
  border-radius: 999px;

  @media screen and (min-width: 768px) {
    width: 60px;
    height: 60px;
    right: prem(92);
    bottom:prem(92);
  }
}

.topBtn::after {
  content: "";
  position: absolute;
  display: block;
  width:prem(12);
  height:prem(12);
  top: 42%;
  left: 40%;
  border-bottom: 2px solid $green;
  border-left: 2px solid $green;
  transform: rotate(135deg);
}

.topBtn.is-show{
  opacity: 1;
  visibility: visible;
}

