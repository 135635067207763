@use "sass:math";

// インナー幅設定
// コンテンツのインナー幅（最も共通の幅が多い部分）
$inner: 1120px;
// ※キュンパス幅ではなくインナーの幅を指す

// 余白
$padding-pc: 40px;
$padding-sp: 20px;

////////////////////
// フォント設定
////////////////////

$base-font-family: "Noto Sans JP", sans-serif;
$second-font-family: "Noto Serif JP", serif;
$english-font-family: "Bitter", serif;
// ※検証ツールでしっかり反映されているか確認する



// フォントウェイト
$regular: 300;
$normal: 400;
$medium: 500;
$semi-bold:600;
$bold: 700;

////////////////////
// 色の指定
////////////////////

//色指定（共通性のあるものは出来る限り指定すると良い）
$black: #111;
$white: #fff;
$main: #60943D;
$gray: #9C9C9C;
$lightgray: #E2E2E2;
$lightgray2: #EFEFEF;
$green: #37482B;


body {
  font-family: $base-font-family;
  background-color: $white;
  color: $black;
  font-weight: $medium;
  line-height: 1.8;
}

body.is-fixed{
  height:100%;
  overflow:hidden;
}
