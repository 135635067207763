@charset "UTF-8";
body {
  font-family: "Noto Sans JP", sans-serif;
  background-color: #fff;
  color: #111;
  font-weight: 500;
  line-height: 1.8;
}

body.is-fixed {
  height: 100%;
  overflow: hidden;
}

.u-desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-desktop {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .u-mobile {
    display: none;
  }
}

/* リキッドレイアウト対応 */
html {
  font-size: 16px;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 1.4285714286vw;
  }
}
@media (min-width: 1120px) {
  html {
    font-size: 16px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeLegibility;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
  width: 100%;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

/* フォームリセット */
input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

/* ホバー */
a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
}

.access {
  padding: 4.25rem 0 4.25rem;
}
@media screen and (min-width: 768px) {
  .access {
    padding: 6.5rem 0 8.0625rem;
  }
}

.access__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .access__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.access__title {
  text-align: center;
}

.access__contents {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .access__contents {
    margin-top: 4.375rem;
  }
}

.access__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
@media screen and (min-width: 768px) {
  .access__wrapper {
    flex-direction: row;
    gap: 2.25rem;
    width: 100%;
  }
}

.access__block {
  background-color: #EFEFEF;
  padding-bottom: 1.1875rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .access__block {
    padding-bottom: 1.6875rem;
  }
}

.access__map {
  width: 100%;
  overflow: hidden;
  aspect-ratio: 335/225;
  position: relative;
}
@media screen and (min-width: 768px) {
  .access__map {
    aspect-ratio: 542/400;
  }
}

.access__map iframe {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.access__information {
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .access__information {
    padding-left: 24px;
    padding-right: 22px;
  }
}

.access__subject {
  color: #60943D;
  font-size: 0.9375rem;
  line-height: 1.8;
  padding-left: 1.6875rem;
  position: relative;
  margin-top: 0.8125rem;
}
@media screen and (min-width: 768px) {
  .access__subject {
    font-size: 1.0625rem;
    padding-left: 2.0625rem;
    margin-top: 1.0625rem;
  }
}

.access__subject::before {
  content: "";
  position: absolute;
  display: block;
  background: url(../images/company/access-icon.png) no-repeat center center/contain;
  width: 1rem;
  height: 1.375rem;
  top: 50%;
  transform: translateY(-44%);
  left: 0.25rem;
}
@media screen and (min-width: 768px) {
  .access__subject::before {
    width: 1.25rem;
    height: 3.25rem;
    transform: translateY(-47%);
  }
}

.access__address {
  font-size: 0.875rem;
  line-height: 1.8;
  margin-top: 0.75rem;
  font-weight: 400;
  padding-bottom: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .access__address {
    font-size: 1rem;
    line-height: 1.8;
    margin-top: 1rem;
    padding-bottom: 1.5rem;
  }
}

.access__btn {
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .access__btn {
    padding-left: 24px;
    padding-right: 22px;
    margin-top: auto;
  }
}

.access__button-map {
  display: inline-block;
  padding: 1.125rem 2.6875rem 1rem 2.375rem;
  border: 1px solid #fff;
  border-radius: 0.625rem;
  font-size: 0.875rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .access__button-map {
    padding: 1.0625rem 2.375rem 1.125rem;
    font-size: 1rem;
  }
}

.access__button-map span {
  line-height: 1;
  padding-right: 3.0625rem;
  position: relative;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .access__button-map span {
    padding-right: 3.0625rem;
  }
}

.access__button-map span::after {
  content: "";
  position: absolute;
  display: block;
  background: url(../images/common/arrow02.webp) no-repeat center center/contain;
  width: 1.4375rem;
  height: 0.5625rem;
  top: 50%;
  transform: translateY(-50%);
  right: -0.375rem;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .access__button-map span::after {
    width: 1.6875rem;
    height: 0.625rem;
    right: -0.0625rem;
  }
}

.access__button-map:hover span::after {
  right: -0.875rem;
}

.accordion__block {
  border-top: 1px solid #E2E2E2;
  border-bottom: 1px solid #E2E2E2;
  background-color: #fff;
  padding: 1.4375rem 0.625rem 1.6875rem;
}
@media screen and (min-width: 768px) {
  .accordion__block {
    padding: 2.5625rem 2.5rem 2.5rem;
  }
}

.accordion__block:nth-of-type(n + 2) {
  border-top: none;
}

.accordion__head {
  display: flex;
  align-items: center;
  -moz-column-gap: 18px;
  column-gap: 18px;
}

.accordion__head:hover {
  cursor: pointer;
}

.accordion__icon-box {
  margin-left: auto;
}

.accordion__body {
  display: none;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.9;
  margin-top: 0.6875rem;
}
@media screen and (min-width: 768px) {
  .accordion__body {
    font-size: 1rem;
    line-height: 1.8;
    margin-top: 1.6875rem;
  }
}

.accordion__body-list {
  display: flex;
  align-items: center;
  -moz-column-gap: 18px;
  column-gap: 18px;
}

.accordion__news {
  display: flex;
  flex-direction: column;
  gap: 0.4375rem;
}
@media screen and (min-width: 768px) {
  .accordion__news {
    gap: 3rem;
    flex-direction: row;
  }
}

.accordion__news-daytime {
  color: #60943D;
  font-size: 12px;
  font-family: "Bitter", serif;
  font-feature-settings: "palt" 1;
}
@media screen and (min-width: 768px) {
  .accordion__news-daytime {
    font-size: 1rem;
  }
}

.accordion__news-text {
  font-size: 0.875rem;
}
@media screen and (min-width: 768px) {
  .accordion__news-text {
    font-size: 1rem;
  }
}

.animation__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #60943D;
  z-index: 100;
  display: grid;
  place-items: center;
  pointer-events: none;
}

.business-sub {
  padding: 3.25rem 0 4.25rem;
}
@media screen and (min-width: 768px) {
  .business-sub {
    padding: 5.5rem 0 8rem;
  }
}

.business-sub__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .business-sub__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.business-sub__card {
  display: flex;
  flex-direction: column;
  gap: 2.9375rem;
}
@media screen and (min-width: 768px) {
  .business-sub__card {
    gap: 5.5625rem;
  }
}

.business {
  padding-top: 2.9375rem;
}
@media screen and (min-width: 768px) {
  .business {
    padding-top: 5rem;
  }
}

.business__contents {
  margin-top: 2rem;
}
@media screen and (min-width: 768px) {
  .business__contents {
    margin-top: 3.375rem;
  }
}

.business__card {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
@media screen and (min-width: 768px) {
  .business__card {
    grid-template-columns: repeat(3, 1fr);
  }
}

.button01 {
  padding: 1.0625rem 2.5625rem;
  border: 1px solid #fff;
  border-radius: 0.625rem;
  color: #fff;
  font-size: 0.875rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .button01 {
    padding: 1.0625rem 2.4375rem 1.125rem;
    font-size: 1rem;
  }
}

.button01:before {
  position: absolute;
  left: 0;
  height: 0;
  right: 0;
  bottom: 0;
}

.button01.button01--map {
  background-color: #fff;
  color: #111;
}

.button01.button01--contact {
  padding: 1.3125rem 2.5rem 1.1875rem;
}
@media screen and (min-width: 768px) {
  .button01.button01--contact {
    padding: 1.5rem 2.4375rem 1.5625rem;
  }
}
@media screen and (max-width: 370px) {
  .button01.button01--contact {
    padding: 1.3125rem 1.875rem 1.1875rem;
  }
}

.button01::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: -3.125rem;
  bottom: 0;
  left: 0;
  border-right: 3.125rem solid transparent;
  border-bottom: 4.8125rem solid #fff;
  transform: translateX(-100%);
  transition: 0.4s ease-in-out;
}

.button01.button01--black {
  border: 1px solid #111;
  color: #111;
}

.button01:hover {
  color: #37482B;
}

.button01:hover::before {
  transform: translateX(0);
}

.button01 span {
  line-height: 1;
  padding-right: 3.0625rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .button01 span {
    padding-right: 3.0625rem;
  }
}

.button01 span::after {
  content: "";
  position: absolute;
  display: block;
  background: url(../images/common/arrow01.webp) no-repeat center center/contain;
  width: 1.4375rem;
  height: 0.5625rem;
  top: 50%;
  transform: translateY(-50%);
  right: -0.25rem;
}
@media screen and (min-width: 768px) {
  .button01 span::after {
    width: 1.625rem;
    height: 0.5625rem;
    right: -0.125rem;
  }
}

.button01.button01--black span::after,
.button01.button01--map span::after {
  background: url(../images/common/arrow02.webp) no-repeat center center/contain;
}

.button01:hover span::after {
  background: url(../images/common/arrow03.webp) no-repeat center center/contain;
}

.button02 {
  padding: 1rem 2.5rem;
  border: 1px solid #9C9C9C;
  border-radius: 0.625rem;
  font-size: 0.875rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  gap: 1.5rem;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .button02 {
    padding: 1.0625rem 2.5rem;
    font-size: 1rem;
  }
}

.button02:hover {
  color: #37482B;
  border-color: #111;
  cursor: pointer;
}

.card01 .card01__link {
  height: 100%;
  display: block;
  overflow: hidden;
  position: relative;
}

.card01__itemBody {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  aspect-ratio: 375/351;
  display: block;
}
@media screen and (min-width: 768px) {
  .card01__itemBody {
    aspect-ratio: initial;
    height: 31.25rem;
  }
}

.card01__itemBody > picture {
  display: block;
  height: inherit;
}

.card01__itemBody > picture img {
  height: inherit;
}

.card01__itemBody::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(17, 17, 17, 0.6);
  z-index: 1;
}

.card01__itemBody picture > img {
  transition: 0.3s ease-out;
  object-fit: cover;
}

.card01__link:hover .card01__itemBody picture > img {
  transform: scale(1.2);
}

.card01__container {
  padding: 2rem 1.25rem 2.5rem;
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .card01__container {
    padding: 2.5rem 3.75rem 2.875rem;
  }
}

.card01__number {
  width: 2.5rem;
  height: 2.9375rem;
}
@media screen and (min-width: 768px) {
  .card01__number {
    width: 3.0625rem;
    height: 3.625rem;
  }
}

.card01__number img {
  width: 100%;
}

.card01__topic {
  display: flex;
  flex-direction: column;
  margin-top: 2.75rem;
  gap: 0.1875rem;
}
@media screen and (min-width: 768px) {
  .card01__topic {
    margin-top: 8.75rem;
    gap: 0px;
  }
}

.card01__topic a:before {
  content: "";
  position: absolute;
  inset: 0;
}

.card01__topic span:first-child {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
  font-family: "Bitter", serif;
  opacity: 0.7;
}

.card01__topic span:nth-of-type(1) {
  font-family: "Bitter", serif;
  font-size: 0.8125rem;
  color: #EFEFEF;
}
@media screen and (min-width: 768px) {
  .card01__topic span:nth-of-type(1) {
    font-size: 0.9375rem;
  }
}

.card01__topic span:nth-of-type(2) {
  font-size: 1.0625rem;
}
@media screen and (min-width: 768px) {
  .card01__topic span:nth-of-type(2) {
    font-size: 1.3125rem;
  }
}

.card01__text {
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 1rem;
  line-height: 1.8;
}
@media screen and (min-width: 768px) {
  .card01__text {
    font-size: 1rem;
    margin-top: 1.1875rem;
  }
}

.card01__button {
  margin-top: auto;
  display: block;
}

.card01__object {
  overflow: hidden !important;
}

.test {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

@media screen and (min-width: 768px) {
  .card02__item-flex:nth-of-type(5) {
    flex-direction: column;
  }
}

.card02__item-flex {
  display: flex;
  flex-direction: column;
  color: #fff;
  border-radius: 0.625rem 0.625rem 0 0;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .card02__item-flex {
    flex-direction: row;
    border-radius: 0.625rem;
  }
}

@media screen and (min-width: 768px) {
  .card02__item:nth-of-type(5) .card02__item-flex {
    border-radius: 0.625rem 0.625rem 0 0;
  }
}

@media screen and (min-width: 768px) {
  .card02__item-flex.card02__item-flex--rev {
    flex-direction: row-reverse;
  }
}

.card02__head {
  aspect-ratio: 335/200;
}
@media screen and (min-width: 768px) {
  .card02__head {
    flex: 29.875rem;
  }
}

.card02__head img {
  height: 100%;
  object-fit: cover;
}

.card02__body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #37482B;
  padding: 1.875rem 1.25rem 1.4375rem;
  border-radius: 0 0 0.625rem 0.625rem;
}
@media screen and (min-width: 768px) {
  .card02__body {
    padding: 3.875rem 3.125rem 3rem 4rem;
    gap: 2rem;
    border-radius: 0rem;
    flex: 40.125rem;
  }
}

@media screen and (min-width: 768px) {
  .card02__item-flex.card02__item-flex--rev .card02__body {
    padding: 3.875rem 4.125rem 3rem 3rem;
  }
}

.card02__title {
  padding-left: 3rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .card02__title {
    padding-left: 4.5625rem;
    font-size: 1.25rem;
  }
}

.card02__title::before {
  content: "";
  position: absolute;
  display: block;
  background: url(../images/common/01-icon.webp) no-repeat center center/contain;
  width: 2rem;
  height: 2.375rem;
  top: 50%;
  transform: translateY(-50%);
  left: 0rem;
}
@media screen and (min-width: 768px) {
  .card02__title::before {
    width: 3.0625rem;
    height: 3.625rem;
  }
}

.card02__item:nth-of-type(5) .card02__body {
  border-radius: 0px;
}

.card02__item:nth-of-type(2) .card02__title::before {
  background: url(../images/common/02-icon.webp) no-repeat center center/contain;
}

.card02__item:nth-of-type(3) .card02__title::before {
  background: url(../images/common/03-icon.webp) no-repeat center center/contain;
}

.card02__item:nth-of-type(4) .card02__title::before {
  background: url(../images/common/04-icon.webp) no-repeat center center/contain;
}

.card02__item:nth-of-type(5) .card02__title::before {
  background: url(../images/common/05-icon.webp) no-repeat center center/contain;
}

.card02__item:nth-of-type(6) .card02__title::before {
  background: url(../images/common/06-icon.webp) no-repeat center center/contain;
}

.card02__text {
  font-size: 0.875rem;
  line-height: 1.8;
  font-weight: 400;
  margin-top: 0.5rem;
}
@media screen and (min-width: 768px) {
  .card02__text {
    font-size: 1rem;
    margin-top: 0.875rem;
  }
}

.card02__role {
  padding: 1.8125rem 0 2.125rem;
  background-color: #E2E2E2;
  border-radius: 0 0 0.625rem 0.625rem;
}
@media screen and (min-width: 768px) {
  .card02__role {
    padding: 2.6875rem 0 2.125rem;
  }
}

.card02__role-subject {
  color: #37482B;
  font-size: 0.9375rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .card02__role-subject {
    font-size: 1.125rem;
  }
}

.card02__container {
  margin-top: 1rem;
  color: #111;
  font-weight: 400;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .card02__container {
    width: calc(100% - 96px);
    margin-top: 1.625rem;
  }
}

.card02__role-parent {
  display: flex;
  flex-direction: column;
  border: 1px solid #9C9C9C;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .card02__role-parent {
    display: none;
  }
}

.card02__role-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem 0.625rem;
  line-height: 1.8;
  font-size: 0.8125rem;
}
@media screen and (min-width: 768px) {
  .card02__role-item {
    border-left: 1px solid #9C9C9C;
  }
}

@media screen and (min-width: 768px) {
  .card02__role-item:nth-of-type(3n + 1) {
    border-left: none;
  }
}

.card02__role-item:not(:nth-of-type(1)) {
  border-top: 1px solid #9C9C9C;
}

.card02__role-item:nth-of-type(10) {
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.card02__role-item:nth-of-type(10) span {
  padding: 0.6875rem 1rem 0.75rem;
  position: relative;
  width: 100%;
  text-align: center;
}

.card02__role-item:nth-of-type(10) span:nth-of-type(2)::after {
  content: "";
  background-image: linear-gradient(to right, #9C9C9C, #9C9C9C 3px, transparent 3px, transparent 6px);
  background-size: 6px 1px;
  background-repeat: repeat-x;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card02__table {
  display: none;
  border-collapse: collapse;
  table-layout: fixed;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .card02__table {
    display: block;
  }
}

.card02__table td {
  border: 1px solid #9C9C9C;
  width: 33.3333%;
  padding: 0.875rem 1rem;
  text-align: center;
  font-weight: 400;
  line-height: 1.8;
  height: 5.625rem;
}

.card02__table tr:nth-of-type(4) .card02__table-main {
  border-right: 0px;
}

.card02__table .card02__table-td {
  text-align: left;
  border-left: 1px dashed #9C9C9C;
}

.card03__item {
  padding-top: 3.25rem;
  margin-top: -3.25rem;
}
@media screen and (min-width: 768px) {
  .card03__item {
    padding-top: 5.5rem;
    margin-top: -5.5rem;
  }
}

.card03__item-flex {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card03__head {
  aspect-ratio: 335/225;
  position: relative;
}
@media screen and (min-width: 768px) {
  .card03__head {
    aspect-ratio: 1120/400;
  }
}

.card03__head:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(17, 17, 17, 0.6);
  height: 100%;
  z-index: 1;
}

.card03__head img {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  height: 100%;
  object-fit: cover;
}

.card03__head:after {
  content: "";
  position: absolute;
  display: block;
  z-index: 2;
}

.card03__item:nth-of-type(1) .card03__head:after {
  background: url(../images/business/business-sub-text01.webp) no-repeat center center/contain;
  width: 9.5625rem;
  height: 3.0625rem;
  bottom: -0.75rem;
  right: 1rem;
}
@media screen and (min-width: 768px) {
  .card03__item:nth-of-type(1) .card03__head:after {
    background: url(../images/business/business-sub-text01_pc.webp) no-repeat center center/contain;
    width: 19.1875rem;
    height: 10.8125rem;
    bottom: -3.6875rem;
    right: 1.4375rem;
  }
}

.card03__item:nth-of-type(2) .card03__head:after {
  background: url(../images/business/business-sub-text02.webp) no-repeat center center/contain;
  width: 18.125rem;
  height: 6rem;
  bottom: -0.4375rem;
  right: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .card03__item:nth-of-type(2) .card03__head:after {
    background: url(../images/business/business-sub-text02_pc.webp) no-repeat center center/contain;
    width: 49.75rem;
    height: 10.8125rem;
    bottom: -3.0625rem;
    right: 1.4375rem;
  }
}

.card03__item:nth-of-type(3) .card03__head:after {
  background: url(../images/business/business-sub-text03.webp) no-repeat center center/contain;
  width: 13.0625rem;
  height: 3rem;
  bottom: -0.5rem;
  right: 1.0625rem;
}
@media screen and (min-width: 768px) {
  .card03__item:nth-of-type(3) .card03__head:after {
    background: url(../images/business/business-sub-text03_pc.webp) no-repeat center center/contain;
    width: 25.5625rem;
    height: 10.8125rem;
    bottom: -3.25rem;
    right: 1.75rem;
  }
}

.card03__body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.8125rem 0.75rem 0px;
}
@media screen and (min-width: 768px) {
  .card03__body {
    flex-direction: row;
    align-items: center;
    padding: 1.125rem 1.5rem 0px;
    gap: 4rem;
  }
}

.card03__title {
  padding-left: 3rem;
  position: relative;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .card03__title {
    padding-left: 4.0625rem;
    font-size: 1.25rem;
    flex: 10.3125rem;
    margin-top: 0.9375rem;
  }
}

.card03__title::before {
  content: "";
  position: absolute;
  display: block;
  background: url(../images/common/01-icon-green.webp) no-repeat center center/contain;
  width: 2.1875rem;
  height: 2.59375rem;
  top: 50%;
  transform: translateY(-50%);
  left: 0rem;
}
@media screen and (min-width: 768px) {
  .card03__title::before {
    width: 3.0625rem;
    height: 3.625rem;
  }
}

.card03__item:nth-of-type(2) .card03__title::before {
  background: url(../images/common/02-icon-green.webp) no-repeat center center/contain;
}

.card03__item:nth-of-type(3) .card03__title::before {
  background: url(../images/common/03-icon-green.webp) no-repeat center center/contain;
}

.card03__text {
  font-size: 0.875rem;
  line-height: 1.8;
  font-weight: 400;
  margin-top: 0.5rem;
}
@media screen and (min-width: 768px) {
  .card03__text {
    font-size: 1rem;
    margin-top: 0.875rem;
    flex: 52.6875rem;
  }
}

.company {
  padding: 3.25rem 0 4.25rem;
}
@media screen and (min-width: 768px) {
  .company {
    padding: 5.5rem 0 6.5rem;
  }
}

@media screen and (min-width: 768px) {
  .company__bg {
    background: url(../images/top/company-bg_pc.webp) no-repeat right center/71.5% 100%;
    padding: 4rem 0;
    margin-top: 0px;
  }
}

.company__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
@media screen and (min-width: 768px) {
  .company__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
    top: 0px;
    position: initial;
  }
}

.company__contents {
  top: 3.25rem;
  display: flex;
  flex-direction: column-reverse;
  gap: 2.5rem;
}
@media screen and (min-width: 768px) {
  .company__contents {
    flex-direction: row-reverse;
    gap: 5rem;
  }
}

.company__body {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .company__body {
    flex: 40rem;
  }
}

.company__message {
  margin-top: 2.1875rem;
  font-family: "Noto Serif JP", serif;
  font-size: 1.15625rem;
  font-weight: 600;
  line-height: 1.8;
  color: #37482B;
}
@media screen and (min-width: 768px) {
  .company__message {
    margin-top: 2.875rem;
    font-size: 1.4375rem;
  }
}

.company__text {
  margin-top: 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.8;
}
@media screen and (min-width: 768px) {
  .company__text {
    margin-top: 2rem;
    font-size: 1rem;
  }
}

.company__button {
  margin-top: 1.875rem;
  display: flex;
  justify-content: flex-start;
}
@media screen and (min-width: 768px) {
  .company__button {
    margin-top: 3rem;
  }
}

.company__thumb {
  padding: 0 1.875rem;
}
@media screen and (min-width: 768px) {
  .company__thumb {
    padding: 0px;
    flex: 25rem;
  }
}

.company__picture {
  position: relative;
  content: "";
  display: block;
  aspect-ratio: 275/290;
}
@media screen and (min-width: 768px) {
  .company__picture {
    aspect-ratio: 400/531;
  }
}

.company__picture img {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.contact {
  color: #fff;
  padding: 1.9375rem 0 3rem;
  background: url(../images/common/contact-bg.webp) no-repeat center center/cover;
  position: relative;
}
@media screen and (min-width: 768px) {
  .contact {
    padding: 3.5rem 0 5rem;
    background: url(../images/common/contact-bg_pc.webp) no-repeat center center/cover;
  }
}

.contact:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(17, 17, 17, 0.6);
  z-index: 1;
}

.contact__inner {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .contact__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.contact__contents {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .contact__contents {
    flex-direction: row;
    gap: 1.875rem;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.contact__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 51.6875rem;
  width: 100%;
  margin-top: 2.125rem;
}
@media screen and (min-width: 768px) {
  .contact__main {
    font-size: 1rem;
    align-items: flex-start;
    margin-top: 1.3125rem;
  }
}

.contact__head {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.8;
}
@media screen and (min-width: 768px) {
  .contact__head {
    font-size: 1rem;
  }
}

.contact__body {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1.1875rem;
  padding: 0 2rem;
}
@media screen and (min-width: 768px) {
  .contact__body {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 2.5625rem;
    align-items: flex-start;
    padding: 0px;
  }
}

.contact__number {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.125rem;
  padding-bottom: 2.0625rem;
  border-bottom: 1px solid #fff;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .contact__number {
    border-bottom: 0px;
    border-right: 1px solid #fff;
    max-width: 51.6875rem;
    width: 48.7%;
    align-items: flex-start;
    gap: 0.4375rem;
    padding-bottom: 0.375rem;
  }
}

.contact__number a {
  font-size: 1.875rem;
  line-height: 1.8em;
  padding-left: 3.1875rem;
  position: relative;
  display: inline-block;
  font-family: "Bitter", serif;
}
@media screen and (min-width: 768px) {
  .contact__number a {
    font-size: 2rem;
    padding-left: 3rem;
    flex: 50%;
  }
}

.contact__number a::before {
  content: "";
  position: absolute;
  display: block;
  background: url(../images/common/phone-icon.webp) no-repeat center center/contain;
  width: 2.6875rem;
  height: 2.6875rem;
  top: 50%;
  transform: translateY(-50%);
  left: 0rem;
}
@media screen and (min-width: 768px) {
  .contact__number a::before {
    background: url(../images/common/phone-icon_pc.webp) no-repeat center center/contain;
    width: 2.5rem;
    height: 2.5rem;
  }
}

.contact__daytime {
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .contact__daytime {
    font-size: 1rem;
    flex: 50%;
    padding-left: 2.375rem;
  }
}

.contact__button {
  display: flex;
  justify-content: right;
  margin-top: 1.9375rem;
}
@media screen and (min-width: 768px) {
  .contact__button {
    width: 51.3%;
    margin-top: 0px;
    padding-top: 0.3125rem;
  }
}

.footer {
  background-color: #fff;
  padding: 2.5625rem 0 1.5rem;
}
@media screen and (min-width: 768px) {
  .footer {
    padding: 3.5rem 0 1.25rem;
  }
}

.footer__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .footer__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.footer__block-upper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__block-upper img {
  width: 15.0625rem;
}
@media screen and (min-width: 768px) {
  .footer__block-upper img {
    width: 15.6875rem;
  }
}

.footer__nav {
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .footer__nav {
    margin-top: 2.5625rem;
  }
}

.footer__nav-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}
@media screen and (min-width: 768px) {
  .footer__nav-list {
    flex-direction: row;
    gap: 6.5rem;
  }
}

.footer__nav-item a {
  font-size: 0.875rem;
  line-height: 3.2;
  position: relative;
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .footer__nav-item a {
    font-size: 1rem;
    line-height: 1.8;
  }
}

.footer__nav-item a::after {
  content: "";
  position: absolute;
  display: block;
  width: 0%;
  height: 1px;
  background-color: #111;
  bottom: -0.375rem;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease-out;
}

@media screen and (min-width: 768px) {
  .footer__nav-item a:hover::after {
    width: 110%;
  }
}

.footer__block-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #E2E2E2;
  padding: 1.5625rem 0 0;
  margin-top: 1.8125rem;
  font-size: 0.8125rem;
}
@media screen and (min-width: 768px) {
  .footer__block-bottom {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2.875rem;
    align-items: flex-start;
  }
}

.footer__address {
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: -0.01em;
}
@media screen and (min-width: 768px) {
  .footer__address {
    align-items: flex-start;
  }
}

.footer__address span {
  letter-spacing: 0.01em;
  line-height: 1.8;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .footer__address span {
    text-align: left;
  }
}

.footer__number {
  margin-top: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.875rem;
}
@media screen and (min-width: 768px) {
  .footer__number {
    margin-top: 0.1875rem;
    gap: 1.125rem;
  }
}

.footer__sub-wrapper {
  display: flex;
  flex-direction: column;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .footer__sub-wrapper {
    align-items: flex-end;
  }
}

.footer__sub {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.8125rem;
  margin-top: 1.5rem;
}
@media screen and (min-width: 768px) {
  .footer__sub {
    gap: 2rem;
    margin-top: 0.1875rem;
    font-size: 0.875rem;
    margin-top: 0.0625rem;
  }
}

.footer__sub a {
  line-height: 1.8em;
  color: #9C9C9C;
  position: relative;
}

.footer__sub a::after {
  content: "";
  position: absolute;
  display: block;
  opacity: 0;
  width: 100%;
  height: 1px;
  background-color: #9C9C9C;
  bottom: -0.1875rem;
  left: 0px;
  transition: 0.5s ease-out;
}

.footer__sub a:hover::after {
  opacity: 1;
}

.footer__privacy-mark {
  text-align: center;
  line-height: 1;
  margin-top: 1.125rem;
}
@media screen and (min-width: 768px) {
  .footer__privacy-mark {
    margin-top: 1.625rem;
  }
}

.greeting {
  padding-top: 3.25rem;
  padding-bottom: 4.25rem;
}
@media screen and (min-width: 768px) {
  .greeting {
    padding-top: 5.5rem;
    padding-bottom: 6.5rem;
  }
}

.greeting__bg {
  background: url(../images/company/greeting-bg.webp);
  background-repeat: no-repeat;
  background-position: right 9.1875rem;
  background-size: 77.6% auto;
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .greeting__bg {
    background: url(../images/company/greeting-bg_pc.webp) no-repeat right center/60% 100%;
    padding-top: 4rem;
    padding-bottom: 4.0625rem;
  }
}

.greeting__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .greeting__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.greeting__contents {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
}
@media screen and (min-width: 768px) {
  .greeting__contents {
    gap: 3.9375rem;
  }
}

.greeting__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}
@media screen and (min-width: 768px) {
  .greeting__wrapper {
    flex-direction: row;
    gap: 5.5625rem;
  }
}

.greeting__message {
  font-size: 1.1875rem;
  font-weight: 600;
  line-height: 1.8;
  font-family: "Noto Serif JP", serif;
  color: #37482B;
  margin-top: 0.1875rem;
  letter-spacing: -0.03em;
}
@media screen and (min-width: 768px) {
  .greeting__message {
    font-size: 1.4375rem;
    line-height: 1.8;
    margin-top: 0.3125rem;
    letter-spacing: 0em;
  }
}

.greeting__text {
  font-size: 0.875rem;
  line-height: 1.8;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .greeting__text {
    font-size: 1rem;
    line-height: 1.8;
  }
}

.greeting__name {
  margin-top: 1.125rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.875rem;
  gap: 0.75rem;
}
@media screen and (min-width: 768px) {
  .greeting__name {
    margin-top: 2.25rem;
    font-size: 1rem;
    gap: 1rem;
  }
}

.greeting__name span:nth-of-type(2) {
  font-size: 0.9375rem;
  font-weight: 600;
  font-family: "Noto Serif JP", serif;
}
@media screen and (min-width: 768px) {
  .greeting__name span:nth-of-type(2) {
    font-size: 1.125rem;
  }
}

.header-sub {
  height: 4.8125rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 130;
  background-color: transparent;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .header-sub {
    height: 6.6875rem;
  }
}

.header-sub.scroll-on {
  background-color: rgba(156, 156, 156, 0.5);
}

.header-sub__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .header-sub__inner {
    max-width: 1260px;
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media screen and (min-width: 1300px) {
  .header-sub__inner {
    max-width: calc(100% - 120px);
  }
}

.header-sub__logo {
  max-width: 12.6875rem;
  height: inherit;
  width: 100%;
  position: inherit;
  z-index: 1000;
}
@media screen and (min-width: 768px) {
  .header-sub__logo {
    max-width: 15.6875rem;
    height: 3.6875rem;
  }
}

.header-sub__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header-sub__nav {
  display: none;
  height: inherit;
}
@media screen and (min-width: 768px) {
  .header-sub__nav {
    display: block;
  }
}

.header-sub__nav-list {
  display: flex;
  height: inherit;
}

.header-sub__nav-item {
  height: inherit;
  display: flex;
  align-items: center;
}

.header-sub__nav-item.header-sub__nav-item--contact {
  margin-left: 2rem;
}

.header-sub__nav-item.header-sub__nav-item--hover {
  margin-left: 2rem;
  display: flex;
  align-items: center;
}

.header-sub__nav-item-flex span {
  padding-left: 2rem;
}

.header-sub__nav-item a {
  padding: 0 1.25rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  line-height: 1;
  color: #fff;
  position: relative;
}
@media screen and (min-width: 1300px) {
  .header-sub__nav-item a {
    padding: 0 2rem;
  }
}

.header-sub__nav-item a::after {
  content: "";
  position: absolute;
  display: block;
  width: 70%;
  height: 2px;
  background-color: #fff;
  bottom: -0.1875rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: 0.4s ease-out;
}

.header-sub__nav-item a:hover::after {
  opacity: 1;
  bottom: 0rem;
}

.header-sub__nav-item.header-sub__nav-item--contact a {
  width: 11rem;
  height: 3.3125rem;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 0.625rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: 0.3s ease-out;
}

.header-sub__nav-item.header-sub__nav-item--contact a::after {
  display: none;
}

.header-sub__nav-item.header-sub__nav-item--contact a:hover {
  opacity: 0.7;
}

.header-sub__nav-item.header-sub__nav-item--contact a span {
  padding-left: 2rem;
  position: relative;
}

.header-sub__nav-item.header-sub__nav-item--contact a span::before {
  content: "";
  position: absolute;
  display: block;
  background: url(../images/common/mail-icon.webp) no-repeat center center/contain;
  width: 1.5rem;
  height: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  left: 0rem;
  transition: 0.3s ease-out;
}

.header-sub__hamburger {
  margin: 0;
  padding: 0;
  outline: none;
  position: relative;
  z-index: 999;
  border: 1px solid #fff;
  border-radius: 0.3125rem;
  width: 2.8125rem;
  height: 2.8125rem;
  cursor: pointer;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .header-sub__hamburger {
    display: none;
  }
}

.header-sub__hamburger span {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 1rem;
  height: 1px;
  background-color: #fff;
  transition: 0.5s;
}

.header-sub__hamburger span:nth-of-type(1) {
  top: -0.375rem;
}

.header-sub__hamburger span:nth-of-type(2) {
  top: 0;
}

.header-sub__hamburger span:nth-of-type(3) {
  top: 0.375rem;
}

.header-sub__hamburger.is-open span:nth-of-type(1) {
  top: 0;
  transform: translateX(-50%) rotate(45deg);
}

.header-sub__hamburger.is-open span:nth-of-type(2) {
  opacity: 0;
}

.header-sub__hamburger.is-open span:nth-of-type(3) {
  top: -0.125rem;
  transform: translateX(-50%) rotate(-45deg);
}

.header-sub__drawer {
  padding: 7.625rem 0 6.25rem;
  display: none;
  position: absolute;
  z-index: 900;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #37482b;
  overflow-y: scroll;
  scrollbar-width: none;
}

.header-sub__drawer::-webkit-scrollbar {
  display: none;
}

.header-sub__drawer-list {
  display: flex;
  flex-direction: column;
  gap: 1.4375rem;
}

.header-sub__drawer-item a {
  font-size: 1.0625rem;
  line-height: 2.8;
  display: block;
  color: #fff;
  text-align: center;
}

.header-sub__drawer-item.header-sub__drawer-item--sub {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.75rem;
  padding-top: 1.25rem;
}

.header-sub__drawer-item--sub a {
  font-size: 0.9375rem;
  line-height: 3;
  padding: 0.9375rem 0;
  font-weight: 300;
}

.header-sub__privacy-mark {
  padding-top: 0.75rem;
  width: 100%;
  text-align: center;
}

.header {
  height: 4.8125rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 130;
  background-color: transparent;
  transition: 0.3s ease-out;
  opacity: 0;
}
@media screen and (min-width: 768px) {
  .header {
    height: 6.6875rem;
  }
}

.header.scroll-on {
  background-color: rgba(156, 156, 156, 0.5);
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .header__inner {
    max-width: 1260px;
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media screen and (min-width: 1300px) {
  .header__inner {
    max-width: calc(100% - 120px);
  }
}

.header__logo {
  max-width: 12.6875rem;
  height: inherit;
  width: 100%;
  position: inherit;
  z-index: 1000;
}
@media screen and (min-width: 768px) {
  .header__logo {
    max-width: 15.6875rem;
    height: 3.6875rem;
  }
}

.header__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header__nav {
  display: none;
  height: inherit;
}
@media screen and (min-width: 768px) {
  .header__nav {
    display: block;
  }
}

.header__nav-list {
  display: flex;
  height: inherit;
}

.header__nav-item {
  height: inherit;
  display: flex;
  align-items: center;
}

.header__nav-item.header__nav-item--contact {
  margin-left: 2rem;
}

.header__nav-item.header__nav-item--hover {
  margin-left: 2rem;
  display: flex;
  align-items: center;
}

.header__nav-item-flex span {
  padding-left: 2rem;
}

.header__nav-item a {
  padding: 0 1.25rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  line-height: 1;
  color: #fff;
  position: relative;
}
@media screen and (min-width: 1300px) {
  .header__nav-item a {
    padding: 0 2rem;
  }
}

.header__nav-item a::after {
  content: "";
  position: absolute;
  display: block;
  width: 70%;
  height: 2px;
  background-color: #fff;
  bottom: -0.1875rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: 0.4s ease-out;
}

.header__nav-item a:hover::after {
  opacity: 1;
  bottom: 0rem;
}

.header__nav-item.header__nav-item--contact a {
  width: 11rem;
  height: 3.3125rem;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 0.625rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: 0.3s ease-out;
}

.header__nav-item.header__nav-item--contact a::after {
  display: none;
}

.header__nav-item.header__nav-item--contact a:hover {
  opacity: 0.7;
}

.header__nav-item.header__nav-item--contact a span {
  padding-left: 2rem;
  position: relative;
}

.header__nav-item.header__nav-item--contact a span::before {
  content: "";
  position: absolute;
  display: block;
  background: url(../images/common/mail-icon.webp) no-repeat center center/contain;
  width: 1.5rem;
  height: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  left: 0rem;
  transition: 0.3s ease-out;
}

.header__hamburger {
  margin: 0;
  padding: 0;
  outline: none;
  position: relative;
  z-index: 999;
  border: 1px solid #fff;
  border-radius: 0.3125rem;
  width: 2.8125rem;
  height: 2.8125rem;
  cursor: pointer;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .header__hamburger {
    display: none;
  }
}

.header__hamburger span {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 1rem;
  height: 1px;
  background-color: #fff;
  transition: 0.5s;
}

.header__hamburger span:nth-of-type(1) {
  top: -0.375rem;
}

.header__hamburger span:nth-of-type(2) {
  top: 0;
}

.header__hamburger span:nth-of-type(3) {
  top: 0.375rem;
}

.header__hamburger.is-open span:nth-of-type(1) {
  top: 0;
  transform: translateX(-50%) rotate(45deg);
}

.header__hamburger.is-open span:nth-of-type(2) {
  opacity: 0;
}

.header__hamburger.is-open span:nth-of-type(3) {
  top: -0.125rem;
  transform: translateX(-50%) rotate(-45deg);
}

.header__drawer {
  padding: 7.625rem 0 6.25rem;
  display: none;
  position: absolute;
  z-index: 900;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #37482b;
  overflow-y: scroll;
  scrollbar-width: none;
}

.header__drawer::-webkit-scrollbar {
  display: none;
}

.header__drawer-list {
  display: flex;
  flex-direction: column;
  gap: 1.4375rem;
}

.header__drawer-item a {
  font-size: 1.0625rem;
  line-height: 2.8;
  display: block;
  color: #fff;
  text-align: center;
}

.header__drawer-item.header__drawer-item--sub {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.75rem;
  padding-top: 1.25rem;
}

.header__drawer-item--sub a {
  font-size: 0.9375rem;
  line-height: 3;
  padding: 0.9375rem 0;
  font-weight: 300;
}

.header__privacy-mark {
  padding-top: 0.75rem;
  width: 100%;
  text-align: center;
}

.icon-box {
  width: 1rem;
  height: 1rem;
  display: block;
  position: relative;
}
@media screen and (min-width: 768px) {
  .icon-box {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.icon-box.icon-box--works {
  width: 1.125rem;
  height: 1.125rem;
}
@media screen and (min-width: 768px) {
  .icon-box.icon-box--works {
    width: 1rem;
    height: 1rem;
  }
}

.icon-box.icon-box--works .icon-box__bar01 {
  height: 1px;
}

.icon-box.icon-box--works .icon-box__bar02 {
  width: 1px;
}

.icon-box__bar01 {
  position: absolute;
  width: 100%;
  height: 0.125rem;
  background-color: #60943D;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  transition: 0.3s ease-out;
}

.icon-box__bar02 {
  position: absolute;
  width: 0.125rem;
  height: 100%;
  background-color: #60943D;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  transition: 0.3s ease-out;
}

.icon-box__bar02.is-open {
  transform: rotate(-90deg);
}

.icon-box02 {
  width: 1rem;
  height: 1rem;
  display: block;
  position: relative;
}
@media screen and (min-width: 768px) {
  .icon-box02 {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.icon-box02.icon-box--works {
  width: 1.125rem;
  height: 1.125rem;
}
@media screen and (min-width: 768px) {
  .icon-box02.icon-box--works {
    width: 1rem;
    height: 1rem;
  }
}

.icon-box02__bar01 {
  position: absolute;
  width: 100%;
  height: 0.125rem;
  background-color: #60943D;
  top: 50%;
  transition: 0.3s ease-out;
}

.icon-box02__bar02 {
  position: absolute;
  width: 0.125rem;
  height: 100%;
  background-color: #60943D;
  left: 45%;
  top: 5%;
  transition: 0.3s ease-out;
}

.icon-box02__bar02.is-open {
  transform: rotate(-90deg);
}

.inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.modal {
  inset: 0;
  margin: auto;
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 100dvh;
  overflow: auto;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s ease-out;
  padding-top: 5.625rem;
  padding-bottom: 6.25rem;
  opacity: 0;
  visibility: hidden;
}
@media screen and (min-width: 768px) {
  .modal {
    padding-top: 5rem;
    padding-bottom: 12.5rem;
  }
}

.modal.is-open {
  opacity: 1;
  visibility: visible;
}

.modal__inner {
  max-width: 23.4375rem;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
@media screen and (min-width: 768px) {
  .modal__inner {
    max-width: 1060px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.modal__close-btn {
  position: absolute;
  left: auto;
  right: 20px;
  top: -3.6875rem;
  display: block;
  width: 1.59375rem;
  height: 1.59375rem;
  background: url(../images/works-sub/close-btn.webp) no-repeat center center/cover;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .modal__close-btn {
    width: 2.1875rem;
    height: 2.1875rem;
    left: calc(100% - 4.6875rem);
    top: -2.0625rem;
  }
}
@media screen and (min-width: 1286px) {
  .modal__close-btn {
    left: calc(100% + 1.25rem);
  }
}

.modal__close-btn:hover {
  opacity: 0.7;
}

.modal .swiper-overflow {
  overflow: visible;
}

.modal .swiper {
  max-width: 335px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .modal .swiper {
    max-width: 980px;
  }
}

.modal .main-slide {
  aspect-ratio: 335/300;
}
@media screen and (min-width: 768px) {
  .modal .main-slide {
    aspect-ratio: 980/540;
  }
}

.modal .main-slide img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.modal .swiper-button-prev,
.modal .swiper-button-next {
  width: 3.25rem;
  height: 3.25rem;
  border: 1px solid #111;
  background-color: #fff;
  border-radius: 0.3125rem;
  top: 21.625rem;
}
@media screen and (min-width: 768px) {
  .modal .swiper-button-prev,
  .modal .swiper-button-next {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 0.625rem;
    top: 16rem;
  }
}

.modal .swiper-button-prev {
  left: 0px;
}
@media screen and (min-width: 768px) {
  .modal .swiper-button-prev {
    left: 0px;
  }
}
@media screen and (min-width: 1286px) {
  .modal .swiper-button-prev {
    left: initial;
    right: calc(100% + 2.5rem);
  }
}

.modal .swiper-button-next {
  left: 4.25rem;
}
@media screen and (min-width: 768px) {
  .modal .swiper-button-next {
    left: calc(100% - 4.5rem);
  }
}
@media screen and (min-width: 1286px) {
  .modal .swiper-button-next {
    left: calc(100% + 2.5rem);
  }
}

.modal .swiper-button-prev::after,
.modal .swiper-button-next::after {
  display: none;
}

.modal .swiper-button-prev::before,
.modal .swiper-button-next::before {
  content: "";
  position: absolute;
  display: block;
  width: 1.625rem;
  height: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.modal .swiper-button-prev::before {
  background: url(../images/works-sub/arrow-prev.webp) no-repeat center center/contain;
  left: 0.6875rem;
}
@media screen and (min-width: 768px) {
  .modal .swiper-button-prev::before {
    left: 1.3125rem;
  }
}

.modal .swiper-button-next::before {
  background: url(../images/works-sub/arrow-next.webp) no-repeat center center/contain;
  right: 0.6875rem;
}
@media screen and (min-width: 768px) {
  .modal .swiper-button-next::before {
    right: 1.3125rem;
  }
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  pointer-events: none;
  opacity: 0;
}

.modal .swiper-margin {
  margin-top: 6.75rem;
}
@media screen and (min-width: 768px) {
  .modal .swiper-margin {
    margin-top: 2.5rem;
  }
}

.modal .sub-slide {
  aspect-ratio: 1/1;
}

.modal .sub-slide img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-out;
}

.modal .sub-slide img:hover {
  opacity: 0.6;
}

.modal .swiper-scrollbar {
  position: relative;
  margin-top: 1.5625rem;
}

.modal .swiper-scrollbar::after {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.25rem;
  margin: auto;
  content: "";
  background-color: #E2E2E2;
}

.modal .swiper-scrollbar-drag {
  height: 20px;
  padding: 8px 0;
  cursor: pointer;
  background-color: #60943D;
  background-clip: content-box;
  top: -0.5rem;
}

.modal .swiper-scrollbar-drag:active {
  background-color: #37482B;
}

.modal .swiper-slide.sub-slide.swiper-slide-thumb-active.swiper-slide-next.swiper-slide-visible {
  border: 3px solid #60943D;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .modal .swiper-slide.sub-slide.swiper-slide-thumb-active.swiper-slide-next.swiper-slide-visible {
    border: 4px solid #60943D;
  }
}

.swiper-slide.sub-slide.swiper-slide-visible.swiper-slide-thumb-active {
  border: 3px solid #60943D;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .swiper-slide.sub-slide.swiper-slide-visible.swiper-slide-thumb-active {
    border: 4px solid #60943D;
  }
}

.modal .swiper-slide.sub-slide.swiper-slide-thumb-active.swiper-slide-next.swiper-slide-visible {
  overflow: hidden;
}

.mv-sub {
  aspect-ratio: 375/230;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .mv-sub {
    background: url(../images/reasons/mv-bg_pc.webp) no-repeat center center/cover;
    aspect-ratio: 1300/400;
  }
}

.mv-sub.mv-sub--reasons {
  background: url(../images/reasons/mv-bg.webp) no-repeat center center/cover;
}
@media screen and (min-width: 768px) {
  .mv-sub.mv-sub--reasons {
    background: url(../images/reasons/mv-bg_pc.webp) no-repeat center center/cover;
  }
}

.mv-sub.mv-sub--company {
  background: url(../images/company/mv-bg.webp) no-repeat center center/cover;
}
@media screen and (min-width: 768px) {
  .mv-sub.mv-sub--company {
    background: url(../images/company/mv-bg_pc.webp) no-repeat center center/cover;
  }
}

.mv-sub.mv-sub--business {
  background: url(../images/business/mv-bg.webp) no-repeat center center/cover;
}
@media screen and (min-width: 768px) {
  .mv-sub.mv-sub--business {
    background: url(../images/business/mv-bg_pc.webp) no-repeat center center/cover;
  }
}

.mv-sub.mv-sub--news {
  background: url(../images/news/mv-bg.webp) no-repeat center center/cover;
}
@media screen and (min-width: 768px) {
  .mv-sub.mv-sub--news {
    background: url(../images/news/mv-bg_pc.webp) no-repeat center center/cover;
  }
}

.mv-sub.mv-sub--policy {
  background: url(../images/policy/mv-bg.webp) no-repeat center center/cover;
}
@media screen and (min-width: 768px) {
  .mv-sub.mv-sub--policy {
    background: url(../images/policy/mv-bg_pc.webp) no-repeat center center/cover;
  }
}

.mv-sub.mv-sub--works-sub {
  background: url(../images/works-sub/mv-bg.webp) no-repeat center center/cover;
}
@media screen and (min-width: 768px) {
  .mv-sub.mv-sub--works-sub {
    background: url(../images/works-sub/mv-bg_pc.webp) no-repeat center center/cover;
  }
}

.mv-sub__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  position: relative;
}
@media screen and (min-width: 768px) {
  .mv-sub__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.mv-sub__catch {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-46%);
}
@media screen and (min-width: 768px) {
  .mv-sub__catch {
    transform: translateY(-57%);
    left: 40px;
  }
}

.mv-sub__sub {
  font-family: "Bitter", serif;
}
@media screen and (min-width: 768px) {
  .mv-sub__sub {
    font-size: 1.375rem;
  }
}

.mv-sub__main {
  font-size: 1.25rem;
  font-family: "Noto Serif JP", serif;
  font-weight: 600;
  margin-top: 0.3125rem;
}
@media screen and (min-width: 768px) {
  .mv-sub__main {
    font-size: 1.875rem;
    margin-top: 0.625rem;
  }
}

.mv-sub__text {
  position: absolute;
  right: 20px;
  bottom: 0.9375rem;
  font-size: 0.875rem;
  font-weight: normal;
}
@media screen and (min-width: 768px) {
  .mv-sub__text {
    font-size: 1rem;
    bottom: 1.625rem;
    right: 40px;
  }
}

.mv-sub__text a {
  position: relative;
}

.mv-sub__text a::after {
  content: "";
  position: absolute;
  display: block;
  opacity: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  bottom: -0.1875rem;
  left: 0px;
  transition: 0.5s ease-out;
}

.mv-sub__text a:hover::after {
  opacity: 1;
}

.mv {
  height: 100svh;
}

.mv__swiper {
  height: inherit;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .mv__swiper {
    display: flex;
    flex-direction: row;
  }
}

.mv__catch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -79%);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  color: #fff;
  z-index: 110;
}
@media screen and (min-width: 768px) {
  .mv__catch {
    transform: translate(-50%, -66%);
  }
}

.mv__catch img {
  width: 7.6875rem;
  height: 8.25rem;
  opacity: 0;
  transform: translateY(2.5rem);
}
@media screen and (min-width: 768px) {
  .mv__catch img {
    width: 11.25rem;
    height: 12.0625rem;
  }
}

.mv__message-kerning {
  letter-spacing: -0.4em;
}

.mv__message {
  font-size: 1.375rem;
  font-weight: 600;
  font-family: "Noto Serif JP", serif;
  margin-top: 0.9375rem;
  letter-spacing: 0.04em;
  padding-left: 0.625rem;
}
@media screen and (min-width: 768px) {
  .mv__message {
    font-size: 2rem;
    margin-top: 1.3125rem;
  }
}

.mv__message span {
  opacity: 0;
}

.mv__message-sub {
  font-family: "Bitter", serif;
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-top: 0.75rem;
  opacity: 0;
  transform: translateY(1.25rem);
}
@media screen and (min-width: 768px) {
  .mv__message-sub {
    font-size: 1.125rem;
  }
}

.mv__news {
  border-radius: 0.625rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1.25rem;
  background-color: #fff;
  padding: 0.9375rem 1.25rem 0.875rem;
  max-width: calc(100% - 20px);
  width: 100%;
  z-index: 2;
}
@media screen and (min-width: 768px) {
  .mv__news {
    max-width: 1120px;
    padding: 1rem 2.5rem;
    bottom: 2.5rem;
    width: calc(100% - 80px);
  }
}

.mv__news-contents {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .mv__news-contents {
    flex-direction: row;
  }
}

.mv__news-title {
  line-height: 1;
  color: #60943D;
  font-family: "Bitter", serif;
  padding-bottom: 0.3125rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .mv__news-title {
    font-size: 1.5rem;
    flex: 0 0 5.375rem;
    margin-top: 0.625rem;
  }
}

.mv__news ul {
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  width: 100%;
}

.mv__news ul li a {
  display: block;
  margin-top: 0.375rem;
  padding-top: 0.5rem;
  border-top: 1px solid #E2E2E2;
  position: relative;
}
@media screen and (min-width: 768px) {
  .mv__news ul li a {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    border-top: none;
    border-left: 1px solid #E2E2E2;
    padding-left: 1.5rem;
    padding-top: 0px;
    line-height: 1.8;
    margin: 0.4375rem 0 0.4375rem;
    padding-right: 3.0625rem;
  }
}

@media screen and (min-width: 768px) {
  .mv__news ul li a::before {
    content: "";
    position: absolute;
    display: block;
    width: 0.0625rem;
    height: 100%;
    background-color: #E2E2E2;
    right: 3.0625rem;
  }
}

.mv__news ul li a::after {
  content: "";
  position: absolute;
  display: block;
  background: url(../images/common/arrow02.webp) no-repeat center center/contain;
  width: 1.5625rem;
  height: 0.4375rem;
  top: 50%;
  transform: translateY(-50%);
  right: 0rem;
}
@media screen and (min-width: 768px) {
  .mv__news ul li a::after {
    right: 0rem;
  }
}

.mv__news-daytime {
  font-size: 12px;
  font-family: "Bitter", serif;
  font-weight: 400;
  color: #9C9C9C;
  font-feature-settings: "palt" 1;
}
@media screen and (min-width: 768px) {
  .mv__news-daytime {
    font-size: 0.9375rem;
  }
}

.mv__news-text {
  font-size: 0.8125rem;
  margin-top: 0.1875rem;
  font-weight: 400;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .mv__news-text {
    font-size: 1rem;
    margin-top: 0;
  }
}

.mv .swiper {
  width: 100%;
  height: 50%;
}
@media screen and (min-width: 768px) {
  .mv .swiper {
    width: 50%;
    height: 100%;
  }
}

.mv .swiper img {
  height: 100%;
  object-fit: cover;
}

.mv__swiper .swiper-slide.swiper-slide-active img,
.mv__swiper .swiper-slide.swiper-slide-duplicate-active img,
.mv__swiper .swiper-slide.swiper-slide-prev img {
  animation: scale 8s linear 0s normal both;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
.mv__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #37482B;
  z-index: 100;
  display: grid;
  place-items: center;
  pointer-events: none;
}

.news-list {
  padding: 3.125rem 0 4.0625rem;
}
@media screen and (min-width: 768px) {
  .news-list {
    padding: 5.5rem 0 8rem;
  }
}

.news-list__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .news-list__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.news-list__contents {
  margin-top: 2.625rem;
}
@media screen and (min-width: 768px) {
  .news-list__contents {
    margin-top: 4.375rem;
  }
}

.outline {
  padding-top: 3rem;
}
@media screen and (min-width: 768px) {
  .outline {
    padding-top: 6.5rem;
  }
}

.outline__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .outline__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.outline__title {
  padding-left: 0.6875rem;
}
@media screen and (min-width: 768px) {
  .outline__title {
    padding-left: 0px;
  }
}

.outline__contents {
  margin-top: 2.625rem;
}
@media screen and (min-width: 768px) {
  .outline__contents {
    margin-top: 4.375rem;
    max-width: 900px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.outline__list {
  display: flex;
  flex-direction: column;
  gap: 1.0625rem;
}
@media screen and (min-width: 768px) {
  .outline__list {
    gap: 2rem;
  }
}

.outline__list dl {
  display: flex;
  gap: 2.75rem;
  font-size: 0.875rem;
  line-height: 1.8;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #E2E2E2;
}
@media screen and (min-width: 768px) {
  .outline__list dl {
    font-size: 1rem;
    padding-bottom: 1.96875rem;
    gap: 5.5rem;
  }
}

.outline__list dl dt {
  flex: 4.6875rem;
}
@media screen and (min-width: 768px) {
  .outline__list dl dt {
    flex: 7rem;
  }
}

.outline__list dl dd {
  flex: 13.5rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
}
@media screen and (min-width: 768px) {
  .outline__list dl dd {
    flex: 43.75rem;
    gap: 1.875rem;
  }
}

.outline__change dd:nth-of-type(1) {
  display: flex;
}
@media screen and (min-width: 768px) {
  .outline__change dd:nth-of-type(1) {
    display: none;
  }
}

.outline__change dd:nth-of-type(2) {
  display: none;
}
@media screen and (min-width: 768px) {
  .outline__change dd:nth-of-type(2) {
    display: block;
  }
}

.outline__skill-wrap {
  display: flex;
}

.outline__skill {
  width: 47%;
}

.outline__headcount {
  width: 53%;
}

.philosophy {
  color: #fff;
  background-color: #37482B;
  padding: 3rem 0 4.25rem;
}
@media screen and (min-width: 768px) {
  .philosophy {
    padding: 4.5rem 0 7.4375rem;
  }
}

.philosophy__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .philosophy__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.philosophy__title {
  text-align: center;
}

.philosophy__contents {
  margin-top: 2.625rem;
  letter-spacing: -0.03em;
}
@media screen and (min-width: 768px) {
  .philosophy__contents {
    margin-top: 4.375rem;
  }
}

.philosophy__wrapper {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}
@media screen and (min-width: 768px) {
  .philosophy__wrapper {
    gap: 4.5rem;
  }
}

.philosophy__head {
  color: #37482B;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 0.625rem;
  padding: 1.125rem 1.25rem 0.9375rem;
}
@media screen and (min-width: 768px) {
  .philosophy__head {
    padding: 1.5rem 2rem;
    font-size: 1.25rem;
  }
}

.philosophy__number {
  font-size: 1.1875rem;
  font-family: "Bitter", serif;
  flex: 2.25rem;
}
@media screen and (min-width: 768px) {
  .philosophy__number {
    flex: 55;
    font-size: 1.5625rem;
  }
}

.philosophy__wish {
  font-family: "Noto Serif JP", serif;
  font-weight: 600;
  flex: 17.5rem;
  line-height: 1.8;
  padding-left: 1.1875rem;
  position: relative;
  letter-spacing: 0em;
}
@media screen and (min-width: 768px) {
  .philosophy__wish {
    flex: 1037;
    padding-left: 1.4375rem;
  }
}

.philosophy__wish::before {
  content: "";
  position: absolute;
  display: block;
  width: 0.0625rem;
  height: 2.8125rem;
  background-color: #9C9C9C;
  top: 50%;
  transform: translateY(-50%);
  left: 0.1875rem;
}

.philosophy__wish.philosophy__wish--1row::before {
  height: 0.9375rem;
}

.philosophy__body {
  display: flex;
  flex-direction: column-reverse;
  gap: 1.6875rem;
  margin-top: 1.5rem;
}
@media screen and (min-width: 768px) {
  .philosophy__body {
    flex-direction: row;
    gap: 4rem;
    margin-top: 2.6875rem;
    width: calc(100% - 4rem);
    margin-left: auto;
    margin-right: auto;
  }
}

.philosophy__text-area {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .philosophy__text-area {
    flex: 38.875rem;
  }
}

.philosophy__text-mass {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  font-size: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .philosophy__text-mass {
    gap: 1.125rem;
  }
}

.philosophy__text-mass:nth-of-type(2) {
  margin-top: 1.5rem;
}
@media screen and (min-width: 768px) {
  .philosophy__text-mass:nth-of-type(2) {
    margin-top: 2.6875rem;
  }
}

.philosophy__subject {
  font-size: 0.9375rem;
  padding-left: 1.8125rem;
  position: relative;
  letter-spacing: 0em;
}
@media screen and (min-width: 768px) {
  .philosophy__subject {
    font-size: 1.125rem;
    padding-left: 1.875rem;
  }
}

.philosophy__subject::before {
  content: "";
  position: absolute;
  display: block;
  background: url(../images/company/philosophy-icon.png) no-repeat center center/contain;
  width: 1.3125rem;
  height: 1.5625rem;
  top: 50%;
  transform: translateY(-54%);
  left: 0rem;
}
@media screen and (min-width: 768px) {
  .philosophy__subject::before {
    width: 1.375rem;
    height: 1.625rem;
  }
}

.philosophy__text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0em;
}
@media screen and (min-width: 768px) {
  .philosophy__text {
    font-size: 1rem;
    line-height: 1.8;
  }
}

.philosophy__thumb {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .philosophy__thumb {
    flex: 23.125rem;
    margin-top: -0.1875rem;
  }
}

.policy {
  padding: 3.25rem 0 4.25rem;
}
@media screen and (min-width: 768px) {
  .policy {
    padding: 5.5625rem 0 7.75rem;
  }
}

.policy__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
@media screen and (min-width: 768px) {
  .policy__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
    top: 0px;
    position: initial;
  }
}

.policy__contents {
  display: flex;
  flex-direction: column;
  gap: 1.4375rem;
}
@media screen and (min-width: 768px) {
  .policy__contents {
    gap: 2.5rem;
  }
}

@media screen and (min-width: 768px) {
  .policy__mass:not(:nth-of-type(1)) .policy__text {
    margin-top: 1rem;
  }
}

.policy__heading {
  font-size: 1.09375rem;
  line-height: 1.9;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid #E2E2E2;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .policy__heading {
    font-size: 1.375rem;
    line-height: 1.9;
  }
}

.policy__tips {
  margin-top: 1.4375rem;
}
@media screen and (min-width: 768px) {
  .policy__tips {
    margin-top: 2.5625rem;
  }
}

@media screen and (min-width: 768px) {
  .policy__tips:not(:nth-of-type(3)) {
    margin-top: 2rem;
  }
}

.policy__title {
  color: #60943D;
}
@media screen and (min-width: 768px) {
  .policy__title {
    font-size: 1.25rem;
    line-height: 1.9;
  }
}

.policy__text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.9;
  margin-top: 0.6875rem;
}
@media screen and (min-width: 768px) {
  .policy__text {
    font-size: 1rem;
    line-height: 1.9;
    margin-top: 0.4375rem;
  }
}

@media screen and (min-width: 768px) {
  .policy__text.policy__text--first {
    margin-top: 0.9375rem;
  }
}

.policy__table {
  margin-top: 0.8125rem;
  border-collapse: collapse;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .policy__table {
    margin-top: 0.9375rem;
    width: calc(100% - 3.75rem);
  }
}

.policy__table th,
.policy__table td {
  font-size: 0.875rem;
  line-height: 1.9;
  border: 1px solid #9C9C9C;
  padding: 0.5625rem 0px;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .policy__table th,
  .policy__table td {
    font-size: 1rem;
    padding: 0.9375rem 0px;
  }
}

.policy__table tr th {
  background-color: #E2E2E2;
  width: 7.25rem;
  padding-left: 1.4375rem;
  padding-right: 1.4375rem;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .policy__table tr th {
    width: 15rem;
    padding-left: 3rem;
  }
}

.policy__table tr td {
  font-weight: 400;
  padding-left: 1.125rem;
  padding-right: 0.75rem;
}
@media screen and (min-width: 768px) {
  .policy__table tr td {
    padding-left: 2.875rem;
    padding-right: 1.25rem;
  }
}

.policy__text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.9;
}

.privacy-mark {
  font-size: 0.6875rem;
  font-weight: 400;
  font-family: "Bitter", serif;
  color: #fff;
  text-align: center;
  display: inline-block;
}

.privacy-mark.privacy-mark--gray {
  color: #9C9C9C;
}
@media screen and (min-width: 768px) {
  .privacy-mark.privacy-mark--gray {
    font-size: 0.75rem;
  }
}

.reasons-sub {
  padding: 2.75rem 0 4.1875rem;
  background: url(../images/reasons/logo.webp);
  background-position: center 2.75rem;
  background-repeat: no-repeat;
  background-size: 7.6875rem 8.25rem;
}
@media screen and (min-width: 768px) {
  .reasons-sub {
    padding: 2.75rem 0 8.0625rem;
    background-position: center 4rem;
    background-size: 8.375rem 9rem;
  }
}

.reasons-sub__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .reasons-sub__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.reasons-sub__message {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.8;
  color: #37482B;
  font-weight: 600;
  font-family: "Noto Serif JP", serif;
  font-size: 1.4375rem;
  margin-top: 3.3125rem;
}
@media screen and (min-width: 768px) {
  .reasons-sub__message {
    font-size: 2.125rem;
    flex-direction: row;
    justify-content: center;
    margin-top: 7.75rem;
  }
}

.reasons-sub__text {
  font-size: 1rem;
  font-family: "Noto Serif JP", serif;
  font-weight: 600;
  line-height: 1.8;
  text-align: center;
  margin-top: 0.75rem;
  color: #37482B;
  letter-spacing: -0.02em;
}
@media screen and (min-width: 768px) {
  .reasons-sub__text {
    margin-top: 0.625rem;
    font-size: 1.1875rem;
    letter-spacing: 0em;
  }
}

.reasons-sub__card {
  margin-top: 3.125rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media screen and (min-width: 768px) {
  .reasons-sub__card {
    margin-top: 5rem;
    gap: 4.0625rem;
  }
}

.reasons {
  background-color: #37482B;
  padding: 3rem 0 4.25rem;
}
@media screen and (min-width: 768px) {
  .reasons {
    padding: 5rem 0 7.5rem;
  }
}

.reasons__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .reasons__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.reasons__contents {
  margin-top: 2.4375rem;
}
@media screen and (min-width: 768px) {
  .reasons__contents {
    margin-top: 3.875rem;
  }
}

@media screen and (min-width: 768px) {
  .reasons-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.reasons__message {
  font-size: 1.125rem;
  color: #fff;
  font-family: "Noto Serif JP", serif;
  font-weight: 500;
  line-height: 1.8;
  font-weight: 600;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 768px) {
  .reasons__message {
    font-size: 1.4375rem;
    letter-spacing: -0.01em;
  }
}

.reasons__button-pc {
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .reasons__button-pc {
    display: flex;
  }
}

.reasons__button {
  justify-content: center;
}

.reasons__button.reasons__button--pc {
  display: none;
}
@media screen and (min-width: 768px) {
  .reasons__button.reasons__button--pc {
    display: flex;
  }
}

.reasons__button.reasons__button--sp {
  display: flex;
  margin-top: 2.25rem;
}
@media screen and (min-width: 768px) {
  .reasons__button.reasons__button--sp {
    display: none;
  }
}

.reasons__list {
  margin-top: 2.125rem;
}
@media screen and (min-width: 768px) {
  .reasons__list {
    margin-top: 3.375rem;
  }
}

.reasons__list ul {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
}
@media screen and (min-width: 768px) {
  .reasons__list ul {
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem 2.5625rem;
  }
}

.reasons__list ul li {
  display: flex;
  flex-direction: column;
  aspect-ratio: 335/218;
  background-color: #fff;
  border-radius: 0.625rem;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .reasons__list ul li {
    aspect-ratio: 346/264;
  }
}

.reasons__picture {
  content: "";
  display: block;
  aspect-ratio: 335/165;
  position: relative;
}
@media screen and (min-width: 768px) {
  .reasons__picture {
    aspect-ratio: 346/200;
  }
}

.reasons__subject {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  line-height: 1;
  aspect-ratio: 335/53;
  padding: 0 1rem;
  font-size: 0.875rem;
}
@media screen and (min-width: 768px) {
  .reasons__subject {
    aspect-ratio: 346/64;
    font-size: 1rem;
    padding: 0 1.5rem;
    gap: 1.0625rem;
  }
}

.reasons__list ul li span {
  font-family: "Bitter", serif;
  color: #60943D;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .reasons__list ul li span {
    font-size: 1.125rem;
  }
}

.reasons__picture img {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 0.5625rem;
}
@media screen and (min-width: 768px) {
  .title {
    gap: 0.6875rem;
  }
}

.title.title--center-left {
  align-items: center;
}
@media screen and (min-width: 768px) {
  .title.title--center-left {
    align-items: flex-start;
    gap: 0.6875rem;
  }
}

.title.title--center {
  align-items: center;
}
@media screen and (min-width: 768px) {
  .title.title--center {
    gap: 0.6875rem;
  }
}

.title__img {
  width: 5.9375rem;
  height: 2.625rem;
}
@media screen and (min-width: 768px) {
  .title__img {
    width: 8.75rem;
    height: 3.8125rem;
  }
}

.title__img.title__img--company {
  background: url(../images/top/company-title.webp) no-repeat center center/cover;
  width: 8.0625rem;
}
@media screen and (min-width: 768px) {
  .title__img.title__img--company {
    background: url(../images/top/company-title_pc.webp) no-repeat center center/cover;
    width: 11.8125rem;
  }
}

.title__img.title__img--business {
  background: url(../images/top/business-title.webp) no-repeat center center/cover;
  width: 7.75rem;
}
@media screen and (min-width: 768px) {
  .title__img.title__img--business {
    background: url(../images/top/business-title_pc.webp) no-repeat center center/cover;
    width: 11.3125rem;
  }
}

.title__img.title__img--reasons {
  background: url(../images/top/reasons-title.webp) no-repeat center center/cover;
  width: 7.25rem;
}
@media screen and (min-width: 768px) {
  .title__img.title__img--reasons {
    background: url(../images/top/reasons-title_pc.webp) no-repeat center center/cover;
    width: 10.6875rem;
  }
}

.title__img.title__img--works {
  background: url(../images/top/works-title.webp) no-repeat center center/cover;
}
@media screen and (min-width: 768px) {
  .title__img.title__img--works {
    background: url(../images/top/works-title_pc.webp) no-repeat center center/cover;
  }
}

.title__img.title__img--contact {
  background: url(../images/common/contact-title.webp) no-repeat center center/cover;
  width: 6.9375rem;
}
@media screen and (min-width: 768px) {
  .title__img.title__img--contact {
    width: 10.0625rem;
  }
}

.title__img.title__img--greeting {
  background: url(../images/company/greeting-title.webp) no-repeat center center/cover;
  width: 7.625rem;
}
@media screen and (min-width: 768px) {
  .title__img.title__img--greeting {
    background: url(../images/company/greeting-title_pc.webp) no-repeat center center/cover;
    width: 11.1875rem;
  }
}

.title__img.title__img--philosophy {
  background: url(../images/company/philosophy-title.webp) no-repeat center center/cover;
  width: 9.125rem;
}
@media screen and (min-width: 768px) {
  .title__img.title__img--philosophy {
    background: url(../images/company/philosophy-title_pc.webp) no-repeat center center/cover;
    width: 13.375rem;
  }
}

.title__img.title__img--outline {
  background: url(../images/company/outline-title.webp) no-repeat center center/cover;
  width: 6.6875rem;
}
@media screen and (min-width: 768px) {
  .title__img.title__img--outline {
    background: url(../images/company/outline-title_pc.webp) no-repeat center center/cover;
    width: 9.875rem;
  }
}

.title__img.title__img--access {
  background: url(../images/company/access-title.webp) no-repeat center center/cover;
  width: 6.25rem;
}
@media screen and (min-width: 768px) {
  .title__img.title__img--access {
    background: url(../images/company/access-title_pc.webp) no-repeat center center/cover;
    width: 9.1875rem;
  }
}

.title__img.title__img--news-list {
  background: url(../images/news/news-list-title.webp) no-repeat center center/cover;
  width: 7.6875rem;
}
@media screen and (min-width: 768px) {
  .title__img.title__img--news-list {
    background: url(../images/news/news-list-title_pc.webp) no-repeat center center/cover;
    width: 11.3125rem;
  }
}

.title__text {
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .title__text {
    font-size: 1rem;
  }
}

.title__text.title__text--black {
  color: #111;
}

.topBtn {
  width: 55px;
  height: 55px;
  justify-content: center;
  border: 2px solid #37482B;
  background-color: rgba(255, 255, 255, 0.5);
  align-items: center;
  z-index: 100;
  transition: opacity 0.3s, visibility 0.3s;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  right: 1.25rem;
  bottom: 1.25rem;
  border-radius: 999px;
}
@media screen and (min-width: 768px) {
  .topBtn {
    width: 60px;
    height: 60px;
    right: 5.75rem;
    bottom: 5.75rem;
  }
}

.topBtn::after {
  content: "";
  position: absolute;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  top: 42%;
  left: 40%;
  border-bottom: 2px solid #37482B;
  border-left: 2px solid #37482B;
  transform: rotate(135deg);
}

.topBtn.is-show {
  opacity: 1;
  visibility: visible;
}

.works-sub {
  padding: 3.25rem 0px 4.3125rem;
}
@media screen and (min-width: 768px) {
  .works-sub {
    padding: 5.5rem 0px 8rem;
  }
}

.works-sub__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .works-sub__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.works-sub__category-box {
  padding: 1.25rem 1.09375rem 2rem;
  background-color: #37482B;
  border-radius: 0.625rem;
}
@media screen and (min-width: 768px) {
  .works-sub__category-box {
    padding: 1.875rem 2.5rem 1.9375rem;
  }
}

.works-sub__category-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.875rem;
}
@media screen and (min-width: 768px) {
  .works-sub__category-wrapper {
    flex-direction: row;
    gap: 3.0625rem;
    text-align: center;
  }
}

.works-sub__category-title {
  color: #fff;
  font-size: 1.1875rem;
  font-family: "Bitter", serif;
}
@media screen and (min-width: 768px) {
  .works-sub__category-title {
    font-size: 1.625rem;
  }
}

.works-sub__category-button {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem 0.40625rem;
}
@media screen and (min-width: 768px) {
  .works-sub__category-button {
    gap: 1.5625rem;
  }
}

.works-sub__category-button li {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-size: 0.875rem;
  padding: 0rem 1.46875rem;
  height: 2.125rem;
  border-radius: 0.4375rem;
  transition: 0.3s ease-out;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .works-sub__category-button li {
    font-size: 1rem;
    padding: 0rem 2.4375rem;
  }
}

.works-sub__category-button li:hover,
.works-sub__category-button li.is-active {
  background-color: #fff;
  color: #37482B;
}

.works-sub__category-button li.is-active {
  cursor: initial;
}

.works-sub__container {
  margin-top: 2.25rem;
}
@media screen and (min-width: 768px) {
  .works-sub__container {
    margin-top: 4rem;
  }
}

.works-sub__items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
}
@media screen and (min-width: 768px) {
  .works-sub__items {
    gap: 3.5rem 2.5625rem;
    grid-template-columns: repeat(3, 1fr);
  }
}

.works-sub__item {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  display: none;
}

.works-sub__item:hover {
  cursor: pointer;
}

.works-sub__picture {
  display: block;
  aspect-ratio: 335/210;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .works-sub__picture {
    aspect-ratio: 346/320;
  }
}

.works-sub__picture img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.works-sub__name {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.8;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}
@media screen and (min-width: 768px) {
  .works-sub__name {
    font-size: 1rem;
    margin-top: 1rem;
  }
}

.works-sub__tag {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  padding: 0.3125rem 0.75rem;
  border-radius: 999px;
  color: white;
  display: inline-block;
  margin-top: auto;
}
@media screen and (min-width: 768px) {
  .works-sub__tag {
    font-size: 0.875rem;
    padding: 0.375rem 0.875rem;
  }
}

.works-sub__tag.works-sub__tag--detached-house {
  background-color: #37482B;
}

.works-sub__tag.works-sub__tag--others {
  background-color: #9C9C9C;
}

.works-sub__tag.works-sub__tag--apartment {
  background-color: #60943D;
}

.works-sub__tag.works-sub__tag--nursing-facility {
  background-color: #37482B;
}

.works-sub__button {
  display: none;
  margin-top: 2.5rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .works-sub__button {
    margin-top: 4.0625rem;
  }
}

.works-sub__button.is-active {
  display: block;
}

.works-sub__button.is-open .icon-box__bar01 {
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.works-sub__button.is-open .icon-box__bar02 {
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.works {
  padding: 4.25rem 0;
  background: url(../images/top/works-bg.webp) no-repeat 4% 12%/92%;
}
@media screen and (min-width: 768px) {
  .works {
    padding: 6rem 0 8rem;
    background: url(../images/top/works-bg_pc.webp) no-repeat -0.5% 42%/39%;
  }
}

.works__inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .works__inner {
    max-width: 1200px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.works__contents {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  font-size: 0.875rem;
}
@media screen and (min-width: 768px) {
  .works__contents {
    flex-direction: row;
    align-items: center;
    gap: 6.5rem;
    font-size: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .works__body {
    flex: 33.625rem;
  }
}

.works__title {
  display: flex;
  justify-content: flex-start;
}

.works__text {
  font-weight: 400;
  margin-top: 2.0625rem;
  line-height: 1.8;
}
@media screen and (min-width: 768px) {
  .works__text {
    margin-top: 3.4375rem;
  }
}

.works__list {
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .works__list {
    margin-top: 1.5625rem;
  }
}

.works__list ul {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5625rem 1.25rem;
}
@media screen and (min-width: 768px) {
  .works__list ul {
    gap: 0.125rem 1.4375rem;
  }
}

.works__list ul li a {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #111;
  padding: 0.6875rem 0.5rem 1rem 0.5rem;
  position: relative;
  color: #37482B;
}
@media screen and (min-width: 768px) {
  .works__list ul li a {
    padding: 1.5625rem 1rem 1.625rem 1rem;
  }
}

.works__list ul li a::after {
  content: "";
  position: absolute;
  display: block;
  background: url(../images/top/arrow02.webp) no-repeat center center/contain;
  width: 1.3125rem;
  height: 0.625rem;
  top: 40%;
  transform: translateY(-50%);
  right: 0.4375rem;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .works__list ul li a::after {
    width: 1.8125rem;
    height: 0.4375rem;
    top: 50%;
    transform: translateY(-50%);
    right: 0.875rem;
  }
}

.works__list ul li a:hover::after {
  right: -0.125rem;
}

@media screen and (min-width: 768px) {
  .works__thumb {
    flex: 29.875rem;
  }
}