@use "global" as *;

.company {
  padding: prem(52) 0 prem(68);
  
  @include mq('md') {
    padding: prem(88) 0 prem(104);
  }
  // background: url(../images/top/company-bg.webp)no-repeat center center/cover;
}

.company__bg {
  // margin-top: 66vw;

  @include mq('md') {

    background: url(../images/top/company-bg_pc.webp)no-repeat right center/71.5% 100%;
    padding: prem(64) 0;
    margin-top: 0px;
  }
}

.company__inner {
  width: 100%;
  padding-right: $padding-sp;
  padding-left: $padding-sp;
  margin-right: auto;
  margin-left: auto;
  position: relative;


  @include mq("md") {
    max-width: $inner + strip-unit($padding-pc) * 2;
    padding-right: $padding-pc;
    padding-left: $padding-pc;
    top:0px;
    position: initial;
  }
}

.company__contents {
  top: prem(52);
  display: flex;
  flex-direction: column-reverse;
  gap: prem(40);

  @include mq('md') {
    flex-direction: row-reverse;
    gap: prem(80);
  }

}

.company__body {
  width: 100%;

  @include mq('md') {
    flex: prem(640);
  }
}

.company__title {}

.company__message {
  margin-top: prem(35);
  font-family: $second-font-family;
  font-size: prem(18.5);
  font-weight: $semi-bold;
  line-height: 1.8;
  color: $green;

  @include mq('md') {
    margin-top: prem(46);
    font-size: prem(23);
  }
}


.company__text {
  margin-top: prem(14);
  font-size: prem(14);
  font-weight: $normal;
  line-height: 1.8;


  @include mq('md') {
    margin-top: prem(32);
    font-size: prem(16);
  }
}

.company__button {
  margin-top: prem(30);
  display: flex;
  justify-content: flex-start;

  @include mq('md') {
    margin-top: prem(48);
  }
}



.company__thumb {
  padding: 0 prem(30);

  @include mq('md') {
    padding: 0px;
    flex: prem(400);
  }
}

.company__picture {
  position: relative;
  content: "";
  display: block;
  aspect-ratio: 275/290;

  @include mq('md') {
    aspect-ratio: 400/531;
  }
}

.company__picture img {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
