@use "global" as *;

.button02 {
    padding: prem(16) prem(40);
    border: 1px solid $gray;
    border-radius: prem(10);
    font-size: prem(14);
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    gap: prem(24);
    font-weight: $normal;

    @include mq('md') {
        padding: prem(17) prem(40);
        font-size: prem(16)
    }
}


.button02:hover {
    color: $green;
    border-color: $black;
    cursor: pointer;
}
