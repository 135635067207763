@use "global" as *;


.accordion__block {
    border-top: 1px solid $lightgray;
    border-bottom: 1px solid $lightgray;
    background-color: #fff;
    padding: prem(23) prem(10) prem(27);

    @include mq('md') {
        padding: prem(41) prem(40) prem(40);
    }
  }



  .accordion__block:nth-of-type(n + 2) {
    border-top: none;
  }



  .accordion__head {
    display: flex;
    align-items: center;
    -moz-column-gap: 18px;
         column-gap: 18px;
  }


  .accordion__head:hover {
    cursor: pointer;
  }


  .accordion__icon-box {
    margin-left: auto;
  }

  .accordion__body {
    display: none;
    font-weight: $normal;
    font-size: prem(14);
    line-height: 1.9;
    margin-top: prem(11);

    @include mq('md') {
      font-size: prem(16);
      line-height: 1.8;
      margin-top: prem(27);
    }
  }



  .accordion__body-list {
    display: flex;
    align-items: center;
    -moz-column-gap: 18px;
         column-gap: 18px;
  }


.accordion__news{
    display: flex;
    flex-direction: column;
    gap: prem(7);


    @include mq('md') {
      gap: prem(48);
      flex-direction: row;
    }
}


.accordion__news-daytime{
    color: $main;
    font-size: 12px;
    font-family: $english-font-family;
    font-feature-settings: "palt" 1;

    @include mq('md') {
      font-size: prem(16);
}
}
.accordion__news-text{
    font-size: prem(14);
    // margin-top: prem(3);

    @include mq('md') {
      font-size: prem(16);
    }
}
