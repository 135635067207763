@use "global" as *;

.news-list {
    padding: prem(50) 0 prem(65);

    @include mq('md') {
        padding: prem(88) 0 prem(128);
    }
}

.news-list__inner {
    width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
    margin-right: auto;
    margin-left: auto;

    @include mq("md") {
        max-width: $inner + strip-unit($padding-pc) * 2;
        padding-right: $padding-pc;
        padding-left: $padding-pc;
    }
}

.news-list__contents {
    margin-top: prem(42);

    @include mq('md') {
        margin-top: prem(70);
    }
}
