@use "global" as *;

.works {
    padding: prem(68) 0;
    background: url(../images/top/works-bg.webp)no-repeat 4% 12%/92%;

    @include mq('md') {
        padding: prem(96) 0 prem(128);
        background: url(../images/top/works-bg_pc.webp)no-repeat -0.5% 42%/39%;

    }
}

.works__inner {
    width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
    margin-right: auto;
    margin-left: auto;

    @include mq("md") {
        max-width: $inner + strip-unit($padding-pc) * 2;
        padding-right: $padding-pc;
        padding-left: $padding-pc;
    }
}

.works__contents {
    display: flex;
    flex-direction: column;
    gap: prem(40);
    font-size: prem(14);

    @include mq('md') {
        flex-direction: row;
        align-items: center;
        gap: prem(104);
        font-size: prem(16);
    }
}

.works__body {
    @include mq('md') {
        flex: prem(538);
    }
}

.works__title {
    display: flex;
    justify-content: flex-start;
}


.works__text {
    font-weight: $normal;
    margin-top: prem(33);
    line-height: 1.8;

    @include mq('md') {
        margin-top: prem(55);
    }
}

.works__list {
    margin-top: prem(20);

    @include mq('md') {
        margin-top: prem(25);
    }
}

.works__list ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: prem(9) prem(20);

    @include mq('md') {
        gap: prem(2) prem(23);
    }
}

.works__list ul li a {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $black;
    padding: prem(11) prem(8) prem(16) prem(8);
    position: relative;
    color: $green;

    @include mq('md') {
        padding: prem(25) prem(16) prem(26) prem(16);
    }
}


.works__list ul li a::after {
    content: "";
    position: absolute;
    display: block;
    background: url(../images/top/arrow02.webp)no-repeat center center/contain;
    width: prem(21);
    height: prem(10);
    top: 40%;
    transform: translateY(-50%);
    right: prem(7);
    transition: .3s ease-out;

    @include mq('md') {
        width: prem(29);
        height: prem(7);
        top: 50%;
        transform: translateY(-50%);
        right: prem(14);

    }
}

.works__list ul li a:hover::after{
    right: prem(-2);
}

.works__thumb {
    @include mq('md') {
        flex: prem(478);
    }
}


