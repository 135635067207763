@use "global" as *;

.title {
    display: flex;
    flex-direction: column;
    gap: prem(9);

    @include mq('md') {
      gap: prem(11);
    }
}

.title.title--center-left{
  align-items: center;

  @include mq('md') {
    align-items: flex-start;
    gap: prem(11);
  }
}

.title.title--center{
  align-items: center;

  @include mq('md') {
    gap: prem(11);
  }
}


.title__img {
  width: prem(95);
  height: prem(42);

  @include mq('md') {
    width: prem(140);
   height: prem(61);
  }
}

.title__img.title__img--company{
  background: url(../images/top/company-title.webp)no-repeat center center/cover;
  width: prem(129);


  @include mq('md') {
    background: url(../images/top/company-title_pc.webp)no-repeat center center/cover;
    width: prem(189);
  }
}


.title__img.title__img--business{
  background: url(../images/top/business-title.webp)no-repeat center center/cover;
  width: prem(124);

  @include mq('md') {
    background: url(../images/top/business-title_pc.webp)no-repeat center center/cover;
    width: prem(181);
  }

}
.title__img.title__img--reasons{
  background: url(../images/top/reasons-title.webp)no-repeat center center/cover;
  width: prem(116);


  @include mq('md') {
    background: url(../images/top/reasons-title_pc.webp)no-repeat center center/cover;
    width: prem(171);
  }
}


.title__img.title__img--works{
  background: url(../images/top/works-title.webp)no-repeat center center/cover;

  @include mq('md') {
    background: url(../images/top/works-title_pc.webp)no-repeat center center/cover;
  }
}

.title__img.title__img--contact{
  background: url(../images/common/contact-title.webp)no-repeat center center/cover;
  width: prem(111);

  @include mq('md') {
   width: prem(161);
  }
}

.title__img.title__img--greeting{
  background: url(../images/company/greeting-title.webp)no-repeat center center/cover;
  width: prem(122);

  @include mq('md') {
    background: url(../images/company/greeting-title_pc.webp)no-repeat center center/cover;
    width: prem(179);
  }
}

.title__img.title__img--philosophy{
  background: url(../images/company/philosophy-title.webp)no-repeat center center/cover;
  width: prem(146);

  @include mq('md') {
    background: url(../images/company/philosophy-title_pc.webp)no-repeat center center/cover;
    width: prem(214);
  }
}

.title__img.title__img--outline{
  background: url(../images/company/outline-title.webp)no-repeat center center/cover;
  width: prem(107);

  @include mq('md') {
    background: url(../images/company/outline-title_pc.webp)no-repeat center center/cover;
    width: prem(158);
  }
}

.title__img.title__img--access{
  background: url(../images/company/access-title.webp)no-repeat center center/cover;
  width: prem(100);

  @include mq('md') {
    background: url(../images/company/access-title_pc.webp)no-repeat center center/cover;
    width: prem(147);
  }
}

.title__img.title__img--news-list{
  background: url(../images/news/news-list-title.webp)no-repeat center center/cover;
  width: prem(123);

  @include mq('md') {
    background: url(../images/news/news-list-title_pc.webp)no-repeat center center/cover;
    width: prem(181);
  }
}


.title__text {
  font-size: prem(14);
  line-height: 1;
  color: $white;
  font-weight: $medium;

  @include mq('md') {
   font-size: prem(16);
  }
}

.title__text.title__text--black {
  color: $black;
}

