@use "global" as *;

.card01

.card01__link {
  height: 100%;
  display: block;
  overflow: hidden;
  position: relative;
}


.card01__itemBody {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  aspect-ratio: 375/351;
  display: block;

  @include mq('md') {
  aspect-ratio: initial;
   height: prem(500);
  }
}

.card01__itemBody > picture{
  display: block;
  height: inherit;
}

.card01__itemBody > picture img{
  height: inherit;
}

.card01__itemBody::before{
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($black, 0.6);
      z-index: 1;
}

.card01__itemBody picture > img{
  transition: .3s ease-out;
  object-fit: cover;
}

.card01__link:hover .card01__itemBody picture > img{
  transform: scale(1.2);
}


.card01__container {
  padding: prem(32) prem(20) prem(40);
  position: absolute;
  left: 0;
  top: 0;
  color: $white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @include mq('md') {
    padding: prem(40) prem(60) prem(46);

  }
}

.card01__number{
  width: prem(40);
  height: prem(47);

  @include mq('md') {
    width: prem(49);
    height: prem(58);
  }
}

.card01__number img{
  width: 100%;
}


.card01__topic {
  display: flex;
  flex-direction: column;
  margin-top: prem(44);
  gap: prem(3);


  @include mq('md') {
    margin-top: prem(140);
    gap: 0px;
  }
}

.card01__topic a:before{
  content: '';
  position: absolute;
  inset: 0;
}

.card01__topic span:first-child {
  font-size: prem(14);
  color: rgba($white, 0.8);
  font-family: $english-font-family;
  opacity: 0.7;
}


.card01__topic span:nth-of-type(1){
  font-family: $english-font-family;
  font-size: prem(13);
  color: $lightgray2;

  @include mq('md') {
    font-size: prem(15);
  }
}

.card01__topic span:nth-of-type(2){
  font-size: prem(17);
  @include mq('md') {
    font-size: prem(21);
  }
}

// .card01__textWrap:not(:nth-of-type(1)) {
//   margin-top: -4px;

// }

.card01__text {
  font-size: prem(14);
  font-weight: $normal;
  margin-top: prem(16);
  line-height: 1.8;

  @include mq('md') {
   font-size: prem(16);
   margin-top: prem(19);
  }
}


.card01__button{
   margin-top: auto;
   display: block;
}

.card01__object{
  overflow: hidden!important;
}

.test{
   position: absolute;
   left: 0;
   top: 0;
   right: 0;
   bottom:0;
}


