@use "global" as *;

.modal {
  inset: 0;
  margin: auto;
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 100dvh;
  overflow: auto;
  z-index: 200;
  // overscroll-behavior-y: none;
  // display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: .3s ease-out;
  padding-top:prem(90);
  padding-bottom: prem(100);
  opacity: 0;
  visibility: hidden;
  // overflow: scroll;

  @include mq('md') {
    padding-top: prem(80);
    padding-bottom: prem(200);
  }
}

.modal.is-open {
  opacity: 1;
  visibility: visible;
}

.modal__inner {
  max-width: prem(375);
  width: 100%;
  padding-right: $padding-sp;
  padding-left: $padding-sp;
  margin-right: auto;
  margin-left: auto;
  position: relative;


  @include mq("md") {
    max-width: 980px + strip-unit($padding-pc) * 2;
    padding-right: $padding-pc;
    padding-left: $padding-pc;
  }
}

// .modal__container {
//   position: absolute;
// }

// .modal__picture {
//   display: block;
//   aspect-ratio: 214/300;
//   position: relative;
//   width: 100%;
// }

// .modal__picture img {
//   position: absolute;
//   display: block;
//   width: 100%;
//   height: 100%;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   object-fit: cover;
//   height: 100%;

// }

// .modal__close-icon {
//   width: 37px;
// }

// .modal__body {
//   margin-top: 12px;
//   line-height: 1.6;
//   font-size: 14px;

//   @media screen and (min-width: 768px) {
//     font-size: 16px;
//   }
// }


// .modal__text {
//   margin-top: 30px;
//   margin-bottom: 30px;
//   // overflow: scroll;
// }

.modal__close-btn {
  position: absolute;
  left: auto;
  right: 20px;
  top: prem(-59);
  display: block;
  width: prem(25.5);
  height: prem(25.5);
  background: url(../images/works-sub/close-btn.webp)no-repeat center center/cover;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    width: prem(35);
    height: prem(35);
    left: calc(100% + prem(-75));
    top: prem(-33);
  }

  @media screen and (min-width: 1286px) {
    left: calc(100% + prem(20));
  }
}

.modal__close-btn:hover{
   opacity: 0.7;
}

// .btn01 {
//   width: 150px;
//   padding: 15px;
//   border: 2px solid #111;
//   border-radius: 60px;
// }



.modal .swiper-overflow{
   overflow: visible;
}



.modal .swiper{
  max-width: 335px;
  margin-right: auto;
  margin-left: auto;

  @include mq('md') {
   max-width: 980px;
  }
}

.modal .main-slide{
  aspect-ratio: 335/300;

  @include mq('md') {
    aspect-ratio: 980/540;
  }
}

.modal .main-slide img{
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.modal .swiper-controller{

}

.modal .swiper-button-prev,
.modal .swiper-button-next {
  width: prem(52);
  height: prem(52);
  border: 1px solid $black;
  background-color: $white;
  border-radius: prem(5);
  top: prem(346);

  @include mq('md') {
    width: prem(72);
    height: prem(72);
    border-radius: prem(10);
    top: prem(256);
  }
}


.modal .swiper-button-prev{
  left:0px;



  @include mq('md') {
   left: 0px;
  }

  @media screen and (min-width: 1286px) {
    left: initial;
    right: calc(100% + prem(40));
  }

}

.modal .swiper-button-next{
  left: prem(68);

  @include mq('md') {
    left:calc(100% - prem(72));
  }

  @media screen and (min-width: 1286px) {
    left:calc(100% + prem(40));
  }

}


.modal .swiper-button-prev::after,
.modal .swiper-button-next::after {
  display: none;
}

.modal .swiper-button-prev::before,
.modal .swiper-button-next::before{
  content: "";
  position: absolute;
  display: block;
  width: prem(26);
  height: prem(8);
  top: 50%;
  transform: translateY(-50%);
}

.modal .swiper-button-prev::before{
  background: url(../images/works-sub/arrow-prev.webp)no-repeat center center/contain;
  left: prem(11);


  @include mq('md') {
    left: prem(21);
  }
}

.modal .swiper-button-next::before{
  background: url(../images/works-sub/arrow-next.webp)no-repeat center center/contain;
  right: prem(11);

  @include mq('md') {
    right: prem(21);
  }
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled{
  pointer-events: none;
  opacity: 0;
}

.modal .swiper-margin{
  margin-top: prem(108);

  @include mq('md') {
   margin-top: prem(40);
  }

}

.modal .sub-slide{
  aspect-ratio: 1/1;

};



.modal .sub-slide img{
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: .3s ease-out;
}

.modal .sub-slide img:hover{
   opacity: 0.6;
}


.modal .swiper-scrollbar {
  position: relative;
  margin-top: prem(25);
}

.modal .swiper-scrollbar::after {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: prem(4);
  margin: auto;
  content: "";
  background-color: $lightgray;
}

.modal .swiper-scrollbar-drag {
  height: 20px;
  padding: 8px 0;
  cursor: pointer;
  background-color: $main;
  background-clip: content-box;
  top: prem(-8);
}

.modal .swiper-scrollbar-drag:active {
  background-color:$green;
}



.modal .swiper-slide.sub-slide.swiper-slide-thumb-active.swiper-slide-next.swiper-slide-visible{
  border: 3px solid $main;
  overflow: hidden;

   @include mq('md') {
    border: 4px solid $main;
   }
}

.swiper-slide.sub-slide.swiper-slide-visible.swiper-slide-thumb-active{
  border: 3px solid $main;
  overflow: hidden;

  @include mq('md') {
   border: 4px solid $main;
  }
}

.modal .swiper-slide.sub-slide.swiper-slide-thumb-active.swiper-slide-next.swiper-slide-visible{
  overflow: hidden;
}
